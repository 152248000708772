import { Routes, Route, useLocation } from 'react-router-dom';
import NavBar from './Components/NavBar';
import Home from './Pages/Home';
import Footer from './Components/Footer';
import Poi from './Pages/Poi';
import React, { useEffect } from 'react';
import AllCategory from './Pages/AllCategory';

import AllBlogs from './Pages/Blogs';
import AllPoi from './Pages/Poi/allPoi';
import AllDestination from './Pages/Destination';
import SingleState from './Pages/City/SingleState';
import AllCity from './Pages/City/AllCity/AllCity';
import CityView from './Pages/City/CityView'
import AllPages from './Pages/AllCategories';
import Cookies from "js-cookie"
import BlogDetails from './Components/LatestBlogs/BlogDetails';
import { url } from './urlConfig';
import axios from "axios";

function App({ initialData ,language }) {
  console.log(language)
  const [currency,setCurrency]=React.useState([])
  // Use useLocation to get the current path
  const location = useLocation();
  useEffect(()=>{

        console.log("hello")
      const fetchPoiData = async () => {
        try {
          const categoryResponse = await axios.get(`${url}/currency/all`);
         setCurrency(JSON.stringify(categoryResponse.data.data))
         console.log("hello match",JSON.stringify(categoryResponse.data.data))
        } catch (error) {
          console.error("Error fetching data on client-side navigation:", error);
       
        }
      };

      fetchPoiData();
   
  }, [ ]);
  // Check if the current route is the 404 NotFound page
  const isNotFound = location.pathname === "/404" || location.pathname === "/notfound"; // Customize based on your routing logic
  const isPathMatched = location.pathname === '/' ||location.pathname === '/ar' || location.pathname === '/category' || location.pathname === '/all-city' || location.pathname.startsWith('/en/place/') || location.pathname.startsWith('/ar/place/') || location.pathname === '/blogs' || location.pathname === '/all-poi';

  return (
    <>
      {/* Render NavBar only if not on NotFound page */}
      {isPathMatched && <NavBar language={language || Cookies.get("lang")} />}
      <Routes>
        <Route path='/' element={<Home initialData={{popular:initialData.Popular,poi:initialData.Attractions,page:initialData.Page}} language={language || Cookies.get("lang")}/>} />
        <Route path='/ar' element={<Home initialData={{popular:initialData.Popular,poi:initialData.Attractions,page:initialData.Page}} language={language || Cookies.get("lang")}/>} />
        {/* <Route path='/category' element={<AllCategory />} /> */}
        <Route path='/en/travel/:parameters' element={<SingleState initialData={{state:initialData.State,page:initialData.Page3}}language={language || Cookies.get("lang")} />} />
        <Route path='/ar/travel/:parameters' element={<SingleState initialData={{state:initialData.State,page:initialData.Page3}}language={language || Cookies.get("lang")} />} />
        <Route path='/en/trip/:parameters' element={<CityView initialData={{city:initialData.City,page:initialData.Page2}} language={language || Cookies.get("lang")}/>} />
        <Route path='/ar/trip/:parameters' element={<CityView initialData={{city:initialData.City,page:initialData.Page2}} language={language || Cookies.get("lang")}/>} />
        <Route path='/en/place/:city/:url' element={<Poi  initialData={{poi:initialData.Poi,category:initialData.Category}} language={language || Cookies.get("lang")} currency={currency}/>} />
        <Route path='/ar/place/:city/:url' element={<Poi  initialData={{poi:initialData.Poi,category:initialData.Category}} language={language || Cookies.get("lang")} currency={currency}/>} />
        <Route path='/blogs' element={<AllBlogs language={language || Cookies.get("lang")}/>}></Route>
        <Route path="/blog/:ID" element={<BlogDetails language={language || Cookies.get("lang")}/>} />
        <Route path='/en/all-poi/:categoryId' element={<AllPoi language={language || Cookies.get("lang")}/>}></Route>
        <Route path='/ar/all-poi/:categoryId' element={<AllPoi language={language || Cookies.get("lang")}/>}></Route>
        <Route path='/en/:location/:slug' element={<AllPages initialData={{page:initialData.Page4}} language={language || Cookies.get("lang")}/>}></Route>
        <Route path='/ar/:location/:slug' element={<AllPages initialData={{page:initialData.Page4}} language={language || Cookies.get("lang")}/>}></Route>
        <Route path='/en/all-states' element={<AllDestination initialData={{state:initialData.State,category:initialData.Category,country:initialData.Country}} language={language || Cookies.get("lang")}/>}></Route>
        <Route path='/en/all-cities' element={<AllCity initialData={{citie:initialData.Citie,state:initialData.State}} language={language || Cookies.get("lang")}/>}></Route>
        <Route path='/ar/all-states' element={<AllDestination initialData={{state:initialData.State,category:initialData.Category,country:initialData.Country}} language={language || Cookies.get("lang")}/>}></Route>
        <Route path='/ar/all-cities' element={<AllCity initialData={{citie:initialData.Citie,state:initialData.State}} language={language || Cookies.get("lang")}/>}></Route>
      </Routes>
      {/* Render Footer only if not on NotFound page */}
      {isPathMatched && <Footer language={language || Cookies.get("lang")} />}
    </>
  );
}

export default App;
