import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { primary } from "../../urlConfig";
import PopularCityCard from "./CityCard";
import AttractionCard from "../../Components/Attractions/AttractionCard";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AllCuisines from "../../Components/cuisines";
import Footer from "../../Components/Footer";
import Cookies from "js-cookie";
import { url } from "../../urlConfig"
import ReactHtmlParser from 'html-react-parser';
import NavBar from "../../Components/NavBar";
import LatestBlogs from "../../Components/LatestBlogs";
import ContentLoader from "react-content-loader";
import CardList2 from "../../Components/Shimmer/card2";
import CardList from "../../Components/Shimmer/Cards";
import Boxes from "../../Components/Shimmer/Boxes";
import Lines from "../../Components/Shimmer/Lines";
import DOMPurify from "dompurify";
import Category from "../../Components/Category";
import axios from "axios";
function SingleState({ initialData = {}, language }) {
  const navigate = useNavigate();
  // const { stateId } = useParams();
  const location =useLocation()
  const [stateData, setStateData] = useState(initialData?.state?.data?.state || {});
  const [cities, setCities] = useState(initialData?.state?.data?.popularCities || []);
  const [pois, setPois] = useState(initialData?.state?.data?.pois || []);
  const [loading, setLoading] = useState(true);
  // const [subCatgeory, setSubCategory] = useState([]);
  const [catgeory, setcategory] = useState(initialData?.page?.data || []);
  const [description, setDescription] = useState('');
  const [ar_description, setar_description] = useState('');

 
  const stateId = location.pathname.match(/([a-f0-9]{24})/); // Match 24-character MongoDB ObjectID
//   console.log({ serverprrooo: language })
// Cookies.set("lang",language)

  useEffect(() => {
    if (initialData.state === undefined) {
      const fetchStateData = async () => {
        const fetchUrl = `${url}/single-state/${stateId[0]}`;
        console.log("Fetching state data from URL:", fetchUrl);

        try {
          const response = await axios.get(fetchUrl, {
            headers: { "Content-Type": "application/json" },
          });

          if (response.data.status) {
            const { state, popularCities, pois, categories } = response.data.data;
            console.log("State Data:", pois);
            setStateData(state);
            setCities(popularCities);
            setPois(pois);
            // setcategory(categories);
          } else {
            console.error("API Error:", response.data.message);
          }
        } catch (error) {
          console.error("Fetch Error:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchStateData();
    } else {
      setLoading(false)
    }

  }, [stateId, stateData, initialData.state]);


  // useEffect(() => {
  //   console.log("Fetching popular pages from the API...");

  //   fetch(`${url}/pages/popular`, {
  //     headers: { "Content-Type": "application/json" },
  //   })
  //     .then((res) => {
  //       console.log("Received response from API:", res);
  //       return res.json();
  //     })
  //     .then((response) => {
  //       console.log("Parsed JSON response:", response);

  //       if (response.status === true) {
  //         console.log("API returned a successful response. Setting data...");
  //         setcategory(response.data);
  //         console.log("SubCategory state updated:", response.data);
  //       } else {
  //         console.warn("API returned an unsuccessful status:", response.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching categories:", error);
  //     });
  // }, []);

  const handleViewCities = () => {
    navigate(`/${language}/all-cities`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Debugging: Log stateData to confirm it's being set
  console.log("State Data:", stateData);

  useEffect(() => {
    fetch(`${url}/all-sub-category`, {
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        console.log(response);
        if (response.status === true) {
          // setSubCategory(response.data);
        }
      });
  }, []);

  function truncateText(text, wordLimit) {
    if (!text) return "";
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  }

  let lineHeight = "30px"
  let fontSize = "20px"
  let color = "#FFFFFF"
  // useEffect(() => {
  //   setDescription(props.description);
  //   setar_description(props.ar_description);
  // }, [props]);
  const styledDescription = description?.replace(
    /<span[^>]*>/g,
    `<span style="font-size: ${fontSize}; line-height: ${lineHeight}; color: ${color};">`
  );
  const sanitizedDescription = typeof styledDescription === 'string' ? styledDescription : '';


  const categoryId = "all"
  const handelExploreMore = () => {
    navigate(`/${language}/all-poi/${categoryId}`); window.scrollTo({
      top: 0,
      behavior: 'smooth', // This adds a smooth scroll effect
    })
  };


  return (
    <div>
      <div className="w-full">
        <NavBar  language={language || Cookies.get("lang")}/>

        {/* State Header */}
        <div>
          {stateData ? (
            <>
              <img
                className="h-[531px] max-sm:h-[400px] w-full"
                src={stateData.Image}
                alt={`${stateData.name} Image`}
              />
              <div
                className="relative lg:bottom-[500px] max-sm:bottom-[100px] lg:ms-20 max-sm:ms-4 text-white"
                dir={language === "ar" ? "rtl" : "ltr"} // Set direction to RTL for Arabic
              >
                <h2
                  className={`mb-80 text-[13px] flex font-medium ${language == "ar" ? "max-sm:hidden" : "max-sm:hidden"}`}
                >
                  <a href="/" className="text-gray-300">
                    {language == "ar"
                      ? stateData?.country?.ar_name // Arabic version of country name
                      : stateData?.country?.name || "Country" // Default (English) version of country name
                    }
                  </a>
                  &nbsp; {" > "}
                  {language == "ar"
                    ? stateData?.ar_name || stateData?.name // Arabic state name
                    : stateData?.name // English state name
                  }
                </h2>

                <h1
                  className="lg:text-[70px] max-sm:text-[40px] font-medium"
                  style={{ fontFamily: "Inter" }}
                >
                  {language == "ar"
                    ? stateData?.nameAr || stateData?.ar_name // Arabic state name
                    : stateData?.name // English state name
                  }
                </h1>

                <p className="mt-2 text-[20px]">
                  {language == "ar"
                    ? stateData?.country?.nameAr || stateData?.country?.ar_name // Arabic country name
                    : stateData?.country?.name || "Country" // English country name
                  }
                </p>
              </div>
            </>
          ) : (
            <ContentLoader
              speed={3}
              width="100%"
              height="750"
              viewBox="0 0 100% 531"
              backgroundColor="#ebedeb"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="15" rx="4" ry="4" width="100%" height="30" />
              <rect x="0" y="45" rx="4" ry="4" width="100%" height="20" />
              <rect x="0" y="75" rx="4" ry="4" width="100%" height="30" />
              <rect x="0" y="110" rx="4" ry="4" width="100%" height="30" />
              <rect x="0" y="150" rx="4" ry="4" width="100%" height="30" />
              <rect x="0" y="190" rx="4" ry="4" width="100%" height="30" />
              <rect x="0" y="230" rx="4" ry="4" width="100%" height="30" />

            </ContentLoader>
          )}
        </div>

        {/* welcome notes and tips */}
        <div
          className={`flex max-sm:flex-col ${language == "ar" ? "flex-row-reverse mr-4" : "flex-row"
            } max-sm:-mt-14 lg:-mt-96`}
        >


          <div className="text-center flex justify-center max-sm:w-[98%] ml-20 max-sm:ms-0  mt- items-center gap-28   max-sm:mt-8 w-[50%] ">
            <div
              className={`${language == "ar" ? "text-center" : "text-center"
                }`}
            >
              <h1 className="italic max-sm:text-[20px] text-[40px] max-sm:font-medium">
                {loading === true ? (
                  <Lines width={"400px"} />
                ) : (
                  language === "ar"
                    ? truncateText(stateData?.ar_blurb, 5)
                    : truncateText(stateData?.blurb, 5)
                )}
              </h1>


              <p className="text-[20px] max-sm:text-[13px] mt-5 max-sm:mt-1 leading-6 max-sm:leading-5 max-sm:ms-1">
                {language === "ar"
                  ? ReactHtmlParser(`<span className="override-html">${truncateText(stateData?.ar_description, 50)}</span>`)
                  : ReactHtmlParser(`<span className="override-html">${truncateText(stateData?.description, 50)}</span>`)}
              </p>

            </div>

            {loading === true ? null : (
              language == "en" && (
                <div className="max-sm:hidden border-l-2 border-[#cacbcc] h-48 mt-4"></div>
              )
            )}

          </div>
          {loading === true ? null : (language == "ar" && (
            <div className="border-l-2 border-[#cacbcc] h-40 mt-4 ml-2 max-sm:hidden "></div>
          ))}

          {loading === true ? (
            <div className="mt-5">
              <Lines width={"400px"} />
            </div>

          ) : (
            language == "ar" ? (
              <div
                className={`flex overflow-x-scroll items-center ml-5 mt-5 max-sm:mt-10 gap-5 max-sm:gap-3 w-[50%] max-sm:w-[100%] max-sm:ms-4 flex-row-reverse`}
              >
               
                  <div
                   
                    className="lg:w-[60%] max-sm:w-[300px] max-sm:ml-1 flex-none bg-[#FFFEEF] lg:h-[160px] max-sm:h-[120px] rounded-xl shadow-md"
                  >
                    <div className="ms-2 mt-1">
                      <p className="mt-5 text-[18px] max-sm:text-[13px] text-start ">
                        {ReactHtmlParser(`<span className="override-html">${truncateText(stateData?.ar_tips, 50)}</span>`)}
                      </p>
                      {/* <p className="mt-8 max-sm:mt-4 text-center items-center text-[18px] max-sm:text-[10px]">
                        {language === "ar" ? truncateText(tip.description, 22) : truncateText(tip.description, 22)}
                      </p> */}
                    </div>
                  </div>
              
              </div>
            ) : (
              <div
                className={`flex overflow-x-scroll items-center ml-5 -mt-1 max-sm:mt-10 gap-5 max-sm:gap-3 w-[50%] max-sm:w-[100%] max-sm:ms-4 flex-row`}
              >
                
                  <div
                  
                    className="lg:w-[70%] mt-2 max-sm:w-[300px] max-sm:ml-1 flex-none bg-[#FFFEEF] lg:h-[170px] max-sm:h-[120px] rounded-xl shadow-md"
                  >
                    <div className="ms-2 mt-1">
                      <p className="mt-5 text-[18px] max-sm:text-[13px] text-center ">
                         {ReactHtmlParser(`<span className="override-html">${truncateText(stateData?.tips, 50)}</span>`)}
                      </p>
                      {/* <p className="mt-6 px-4 pb-5 max-sm:mt-4 text-center items-center text-[18px] leading-6 max-sm:leading-3 max-sm:text-[10px]">
                        {language === "ar" ? truncateText(tip.description, 22) : truncateText(tip.description, 22)}
                      </p> */}
                    </div>
                  </div>
             
              </div>
            )
          )}





        </div>



        {/* Popular Destinations */}
        <div

          className={`flex ${language == "ar" ? "flex-row-reverse text-right max-sm:me-3 " : "flex-row text-left"
            } lg:ms-20 max-sm:ms-4 mt-32 max-sm:mt-5`}
        >
          <div>
            <p className="text-[25px] max-sm:text-[16px] font-light">
              {language == "ar" ? "إلى أين تذهب" : "Where To Go"}
            </p>
            <p
              className="lg:text-[40px] max-sm:text-[20px] font-bold text-[#5B27D5]"
            >
              {language == "ar" ? "الوجهات الشعبية" : "Popular Destinations"}
            </p>
          </div>
          <div
            className={`w-[50%] ${language == "ar" ? "me-2" : ""
              }`}
            style={{ borderBottom: "1px solid black" }}
          />
          {language == "en" && (
            <div className="max-sm:hidden me-10 mt-6">
              <KeyboardArrowLeftIcon
                className="rounded-full cursor-pointer"
                style={{
                  border: "1px solid black",
                  fontSize: "40px",
                }}
              />
              <KeyboardArrowRightIcon
                className="rounded-full ms-4 cursor-pointer text-[#5B27D5]"
                style={{
                  border: "1px solid",
                  fontSize: "40px",
                }}
              />
            </div>
          )}

        </div>



        {loading === true ?
          <div>
            <div className="flex  lg:ms-20 max-sm:ms-5 lg:gap-20 max-sm:gap-5  overscroll-x-conatin overflow-x-scroll mt-5">

              <CardList2 />
              <CardList2 />
              <CardList2 />
              <CardList2 />
              <CardList2 />

            </div>
          </div>
          : (
            <div
              className={`flex lg:[90%] lg:ms-20 max-sm:ms-5 lg:gap-5 max-sm:gap-5 overscroll-x-contain overflow-x-scroll lg:mt-8 max-sm:mt-5 ${language == "ar" ? "flex-row-reverse max-sm:me-3" : "flex-row"
                }`}
            >
              {cities.length > 0 ? (
                cities.map((city, index) => (
                  <PopularCityCard key={index} city={city} stateId={stateId} state2={stateData} language={language} />
                ))
              ) : (
                <p>
                  {language == "ar"
                    ? "لا توجد وجهات متاحة"
                    : "No destinations available"}
                </p>
              )}
            </div>
          )}

        <div className={`lg:mt-0 max-sm:-mt-6 mb-5 ${language == "ar" ? "lg:me-20 max-sm:me-5" : "lg:ms-20 max-sm:ms-5"}`}>
          {/* {language === "en" && (
            <>
             
            </>
          )} */}
          {language == "ar" ? (
            <div className="flex flex-row-reverse max-sm:me-3">
              <button
                onClick={handleViewCities}
                className="max-sm:hidden border-2 border-black font-semibold rounded-full -mt-7 -me-16 max-sm:text-[15px] max-sm:px-3 max-sm:p-0 px-7 py-1 hover:bg-[#5B27D5] hover:border-white hover:text-white"
              >
                المزيد من الاستكشاف <ChevronRightIcon sx={{ transform: "rotate(180deg)" }} />
              </button>
              <button
                onClick={handleViewCities}
                className="lg:hidden border-2 border-black font-semibold rounded-full mt-2 -me-4 max-sm:text-[14px] max-sm:px-3 max-sm:p-0 px-7 py-1 hover:bg-[#5B27D5] hover:border-white hover:text-white"
              >
                المزيد من الاستكشاف <ChevronRightIcon sx={{ fontSize: "16px", transform: "rotate(180deg)" }} />
              </button>
            </div>
          ):(
<>
<button
                onClick={handleViewCities}
                className="max-sm:hidden border-2 border-black font-semibold rounded-full -mt-28 max-sm:text-[15px] max-sm:px-3 max-sm:p-0 px-7 py-1 hover:bg-[#5B27D5] hover:border-white hover:text-white"
              >
                Explore More <ChevronRightIcon />
              </button>
              <button
                onClick={handleViewCities}
                className="lg:hidden border-2 border-black font-semibold rounded-full mt-2 max-sm:text-[14px] max-sm:px-3 max-sm:p-0 px-7 py-1 hover:bg-[#5B27D5] hover:border-white hover:text-white"
              >
                Explore More <ChevronRightIcon sx={{ fontSize: "16px" }} />
              </button>
</>
          )
          }

        </div>


        {/* Popular POIs */}
        <div dir={language == "ar" ? "rtl" : "ltr"}
          className=""
        >
          {/* {load === true ? ( */}
          <div className="lg:py-2 ">
            <div className={`flex ${language == "ar" ? "lg:mr-5 max-sm:me-3" : "lg:ms-20 max-sm:ms-4"} mt-32 max-sm:mt-10`}>
              <div>
                <p className="text-[25px] max-sm:text-[16px] font-light">
                  {language == "ar" ? "استكشاف" : "Exploring"}
                </p>
                <p className="lg:text-[40px] max-sm:text-[20px] font-bold text-[#5B27D5] mt-1">
                  {language == "ar" ? `${pois[0]?.cityData?.ar_name}'s جمال` : `${pois[0]?.cityData?.name}'s Beauty`}
                </p>
              </div>
              <div className="lg:w-[60%] max-sm:w-[50%]" style={{ borderBottom: "1px solid black" }}></div>

              <div className={`max-sm:hidden ms-10 mt-5 ${language == "ar" ? "hidden" : ""}`}>
                <KeyboardArrowLeftIcon
                  className="rounded-full cursor-pointer"
                  style={{ border: "1px solid black", fontSize: "40px" }}
                />
                <KeyboardArrowRightIcon
                  className="rounded-full ms-4 cursor-pointer text-[#5B27D5]"
                  style={{ border: `1px solid `, fontSize: "40px" }}
                />
              </div>

            </div>

            <div
              className={`lg:w-[94%] max-sm:w-[100%] bg-gradient-to-b from-white via-[#f4f1cf] to-white flex ${language === "en" ? "lg:ms-20 max-sm:ms-5" : ""
                }  lg:gap-10 max-sm:-space-x-5 overflow-y-hidden overscroll-x-contain overflow-x-scroll mt-5`}
            >
              {pois?.length > 0 ? (
                pois?.map((i) => {
                  // Calculate `city2` and `url2` inside the map
                  const city2 = i?.cityData?.name?.toLocaleLowerCase().replace(/\s+/g, '-');
                  const url = `${i.slug}-${i?.cityData?.name}-${i?.countryData?.name}-poi-${i._id}`;
                  const url2 = url.toLowerCase().replace(/\s+/g, '-');
                  const image = i.images[0]
                  const isArabic = language == "ar";
                  const navigateUrl = isArabic
                    ? `/ar/place/${city2}/${url2}`
                    : `/en/place/${city2}/${url2}`;
                  const title = isArabic ? i.ar_title : i.title;
                  const stateName = isArabic ? i?.stateData?.ar_name : i?.stateData?.name;
                  const description = isArabic
                    ? truncateText(i.ar_shortDescription, 130)
                    : truncateText(i.shortDescription, 130);

                  return (

                    <div
                      key={i._id}
                      className="lg:w-[404px] max-sm:w-[90%]   flex-none mb-5 cursor-pointer"
                      onClick={() => {
                        navigate(navigateUrl);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <div>
                        <img
                          loading="lazy"
                          src={image}
                          alt={`${title} image`}
                          className="lg:w-[404px] lg:h-[280px] max-sm:w-[90%] max-sm:h-[200px] rounded-md hover:shadow-xl"
                        />
                      </div>
                      <div className="py-4">
                        <p className="text-[20px] max-sm:text-[15px]">
                          <b className="text-[22px] max-sm:text-[20px]">{title}, </b>
                          {stateName}
                        </p>
                        <div
                          className="lg:mt-5 max-sm:mt-3 max-sm:w-[90%] text-[17px] max-sm:text-[10px] lg:leading-5 max-sm:leading-3"
                          dangerouslySetInnerHTML={{
                            __html: `<span class="override-html">${truncateText(description, 30)}</span>`,
                          }}
                        ></div>

                      </div>
                    </div>
                  );
                })
              ) : (
                <p>
                  {language == "ar"
                    ? "لا توجد نقاط اهتمام متاحة."
                    : "No Points of Interest available."}
                </p>
              )}

            </div>
          </div>
          {/* //     ) : (
      //       <div className="flex  lg:ms-20 max-sm:ms-5 lg:gap-20 max-sm:gap-5  overscroll-x-conatin overflow-x-scroll mt-5">

      //         <CardList2 />
      //         <CardList2 />
      //         <CardList2 />
      //         <CardList2 />
      //         <CardList2 />

      //       </div>
      //  )}  */}
        </div>

      </div>
      <div>
        <div>
          {/* For Arabic */}
          <div className={`lg:mt-0 max-sm:-mt-6 mb-5 ${language == "ar" ? "lg:me-20 max-sm:me-5" : "lg:ms-20 max-sm:ms-5"}`}>
            {/* For English */}
            {language == "en" && (
              <>
                <button
                  onClick={handelExploreMore}
                  className="max-sm:hidden border-2 border-black font-semibold rounded-full -mt-28 max-sm:text-[15px] max-sm:px-3 max-sm:p-0 px-7 py-1 hover:bg-[#5B27D5] hover:border-white hover:text-white"
                >
                  Explore More <ChevronRightIcon />
                </button>
                <button
                  onClick={handelExploreMore}
                  className="lg:hidden border-2 border-black font-semibold rounded-full mt-3 max-sm:text-[14px] max-sm:px-3 max-sm:p-0 px-7 py-1 hover:bg-[#5B27D5] hover:border-white hover:text-white"
                >
                  Explore More <ChevronRightIcon sx={{ fontSize: "16px" }} />
                </button>
              </>
            )}

            {/* For Arabic */}
            {language ==="ar" && (
              <div className="flex flex-row-reverse">
                <button
                  onClick={handelExploreMore}
                  className="max-sm:hidden border-2 border-black font-semibold rounded-full -mt-7 -me-16 max-sm:text-[15px] max-sm:px-3 max-sm:p-0 px-7 py-1 hover:bg-[#5B27D5] hover:border-white hover:text-white"
                >
                  المزيد من الاستكشاف <ChevronRightIcon sx={{ transform: "rotate(180deg)" }} />
                </button>
                <button
                  onClick={handelExploreMore}
                  className="lg:hidden border-2 border-black font-semibold rounded-full mt-2 -me-4 max-sm:text-[14px] max-sm:px-3 max-sm:p-0 px-7 py-1 hover:bg-[#5B27D5] hover:border-white hover:text-white"
                >
                  المزيد من الاستكشاف <ChevronRightIcon sx={{ fontSize: "16px", transform: "rotate(180deg)" }} />
                </button>
              </div>
            )}
          </div>


          {/* SubCategories Section */}
          <div>
            <Category page={catgeory} language={language} />

          </div>
        </div>



      </div>

      <AllCuisines language={language} />
      <div className="-mt-16">
        <LatestBlogs language={language} />
      </div>


      <Footer language={language} />
    </div>
  );
}

export default SingleState;
