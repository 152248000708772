import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../public/images/hopdayslogo.png";

function Footer({language}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [lang, setLang] = useState(language);

  // useEffect(() => {
  //   if (location.pathname.includes("en") && Cookies.get('lang') === "ar") {
  //     Cookies.set('lang', "en", { expires: 7 });
  //     setLang("en");
  //     window.location.reload()
  //   }
  //   if (location.pathname.includes("ar") && Cookies.get('lang') === "en") {
  //     Cookies.set('lang', "ar", { expires: 7 });
  //     setLang("ar");
  //     window.location.reload()
  //   }
  // }, [location]);

  return (
    <>
      <Divider className="bg-black max-sm:mt-[100px]" style={{ marginTop: "100px" }} />

      {/* Desktop Layout */}
      <div className="mt-10 max-sm:hidden">
        <div className="lg:flex justify-between">
          <div className="lg:ms-20 w-[40%]">
            <div>
              <img src={logo} className="lg:w-1/2 " />
            </div>
            <div className="w-[48%]" style={{ borderBottom: "1px solid gray" }}></div>
            <div className="flex gap-9 ms-0 text-[#5B27D5] mt-5">
              <InstagramIcon />
              <LinkedInIcon />
              <XIcon />
              <FacebookIcon />
            </div>
          </div>

          {/* Important Links Section */}
          {lang === "ar" ? (
            <div className="ms-64 mt-5 w-[30%] text-right me-5">
              <p className="font-bold text-xl max-sm:text-[12px] text-[#5B27D5]">روابط هامة</p>
              <ul className="space-y-3 mt-2">
                <li>الأماكن</li>
                <li>معلومات عنا</li>
                <li>المدونات</li>
              </ul>
            </div>
          ) : (
            <div className="ms-64 mt-5 w-[30%]">
              <p className="font-bold text-[16px] max-sm:text-[12px] text-[#5B27D5]">Important Links</p>
              <ul className="space-y-3 mt-5 text-[16px] text-gray-400 font-medium">
                <li>Destinations</li>
                <li>About Us</li>
                <li>Blogs</li>
              </ul>
            </div>
          )}

          {/* Membership Section */}
          {lang === "ar" ? (
            <div className="ms-10 mt-5 w-[30%] text-right me-14">
              <p className="font-bold text-xl text-[#5B27D5]">أصبح عضوا</p>
              <p className="mt-2 max-sm:text-sm">انضم إلى مجتمع HopDays للمسافرين</p>
              <button className="px-4 max-sm:px-3 max-sm:text-sm rounded-full bg-[#9747FF] text-white py-1 mt-4">إنشاء حساب</button>
            </div>
          ) : (
            <div className="ms-10 mt-5 w-[30%]">
              <p className="font-bold text-[16px] text-[#5B27D5]">Become A Member</p>
              <p className="mt-5 text-gray-400 text-[16px] font-medium leading-5">Join the HopDays<br /> community of travelers</p>
              <button className="px-6 text-[18px] rounded-full bg-[#9747FF] text-white py-1 mt-4">Create Account</button>
            </div>
          )}
        </div>

        {/* Footer Bottom Section */}
        {lang === "ar" ? (
          <div className="bg-gradient-to-r from-yellow-50 to-yellow-50 flex flex-row-reverse w-full  px-20 py-5 mt-5 gap-20">
            <p>2024 هوب دايز. كل الحقوق محفوظة. لا يجوز إعادة إنتاج أي جزء من هذا الموقع دون الحصول على إذن كتابي منا.</p>
            <select
              className="me-40 rounded-full px-10 py-1"
              onChange={(e) => {
                Cookies.set('lang', e.target.value, { expires: 7 });
                setLang(e.target.value);
                if (Cookies.get('lang') === "ar") {
                  const newUrl = location.pathname.replace("/en/", "/ar/");
                  navigate(newUrl);
                  
                }
                if (Cookies.get('lang') === "en") {
                  const newUrl = location.pathname.replace("/ar/", "/en/");
                  navigate(newUrl);
                  window.location.reload()
                }
              }}
              value={lang}
            >
              <option value="en">English</option>
              <option value="ar">Arabic</option>
            </select>
          </div>
        ) : (
          <div className="bg-gradient-to-r from-yellow-50 to-yellow-50 flex w-full px-20 py-5 mt-5 gap-20">
            <p className="font-medium text-[16px] mt-3">© 2024 HopDays. All rights reserved. No part of this site may be reproduced without our written permission.</p>
            <select
              className="ms-40 ps-2 text-[14px] rounded-full px-28 font-medium py-1 border border-[#a481f7]"
              onChange={(e) => {
                Cookies.set('lang', e.target.value, { expires: 7 });
                setLang(e.target.value);
                if (Cookies.get('lang') === "ar") {
                  const newUrl = location.pathname.replace("/en/", "/ar/");
                  navigate(newUrl);
                  window.location.reload()
                }
                if (Cookies.get('lang') === "en") {
                  const newUrl = location.pathname.replace("/ar/", "/en/");
                  navigate(newUrl);
                  window.location.reload()
                }
              }}
              value={lang}
            >
              <option value="en">English</option>
              <option value="ar">Arabic</option>
            </select>
          </div>
        )}
      </div>

      {/* Mobile Layout */}
      <div className="lg:hidden">
        <div>
          <div className="w-[100%] flex justify-center">
            <img src={logo} className="w-[50%]" />
          </div>
          <div className="lg:hidden ms-24 max-sm:w-[46%]" style={{ borderBottom: "1px solid gray" }}></div>
          <div className="flex text-[#5B27D5] justify-center gap-4 mt-2">
            <InstagramIcon />
            <LinkedInIcon />
            <XIcon />
            <FacebookIcon />
          </div>
        </div>

        {/* Mobile Important Links and Membership Section */}
        <div className={`flex gap-5 ms-5 mt-10 justify-center ${lang === "ar" ? "flex-row-reverse me-10" : "flex-row"}`}>
  <div className={`w-[45%] ${lang === "ar" ? "text-right" : "text-left"}`}>
    <p className={`font-bold text-[#5B27D5] max-sm:text-[12px] ${lang === "ar" ? "text-right" : "text-left"}`}>
      {lang === "ar" ? "روابط هامة" : "Important Links"}
    </p>
    <ul className={`space-y-1 max-sm:space-y-2 text-gray-500 mt-2 max-sm:mt-4 max-sm:text-[10px] ${lang === "ar" ? "text-right" : "text-left"}`}>
      <li>{lang === "ar" ? "الوجهات" : "Destinations"}</li>
      <li>{lang === "ar" ? "معلومات عنا" : "About Us"}</li>
      <li>{lang === "ar" ? "المدونات" : "Blogs"}</li>
    </ul>
  </div>
  <div className="" style={{ borderLeft: lang === "ar" ? "none" : "1px solid black", borderRight: lang === "ar" ? "1px solid black" : "none" }}></div>
  <div className={`w-[50%] ${lang === "ar" ? "text-right" : "text-left"}`}>
    <p className={`font-bold text-[#5B27D5] ${lang === "ar" ? "text-right" : "text-left"}`}>
      {lang === "ar" ? "أصبح عضوا" : "Become A Member"}
    </p>
    <p className={`mt-2 max-sm:mt-4 max-sm:text-[10px] max-sm:w-[70%] text-gray-500 max-sm:leading-4 ${lang === "ar" ? "text-right" : "text-left"}`}>
      {lang === "ar" ? "انضم إلى مجتمع HopDays للمسافرين" : "Join the HopDays community of travelers"}
    </p>
    <button className={`px-4 max-sm:text-[10px] rounded-full bg-[#9747FF] text-white py-1 mt-4 ${lang === "ar" ? "text-right" : "text-left"}`}>
      {lang === "ar" ? "إنشاء حساب" : "Create Account"}
    </button>
  </div>
</div>


        {/* Mobile Footer Bottom Section */}
        <div className={`bg-gradient-to-r from-yellow-50 to-yellow-50 text-center w-full px-5 py-5 mt-5 gap-20 ${lang === "ar" ? "text-right" : "text-left"}`}>
  <select
    className={`rounded-full border border-[#a481f7] px-20 py-1 ${lang === "ar" ? "text-right" : "text-left"}`}
    onChange={(e) => {
      Cookies.set("lang", e.target.value, { expires: 7 });
      window.location.reload();
    }}
    value={lang}
  >
    <option value="en" className={lang === "ar" ? "text-right" : "text-left"}>English</option>
    <option value="ar" className={lang === "ar" ? "text-right" : "text-left"}>Arabic</option>
  </select>

  <p className={`mt-3 font-semibold ${lang === "ar" ? "text-right" : "text-left"} max-sm:text-[10px] max-sm:font-medium max-sm:leading-4 max-sm:mt-4`}>
    {lang === "ar"
      ? "© 2024 هوب دايز. كل الحقوق محفوظة. لا يجوز إعادة إنتاج أي جزء من هذا الموقع دون الحصول على إذن كتابي منا."
      : "© 2024 HopDays. All rights reserved. No part of this site may be reproduced without our written permission."
    }
  </p>
</div>

      </div>
    </>
  );
}

export default Footer;
