import React from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { primary, url } from "../../AppConfig";
import Cookies from "js-cookie"; // Assuming you are using Cookies to manage the language

function AllCuisines({language}) {
    return (
        <div>
            {/* Heading Section */}
            <div className={`flex ${language === "ar" ? "flex-row-reverse" : ""} ml-20 max-sm:ms-4 mt-20 max-sm:mt-10`}>
                <div>
                    <p className={`text-[25px] font-light max-sm:text-[16px] ${language === "ar" ? "text-right" : "text-left"}`}>
                        {language === "ar" ? "استكشاف أفضل" : "Explore Best"}
                    </p>
                    <p
                        className={`lg:text-[40px] max-sm:text-[20px] font-bold mt-2 max-sm:mt-1 ${language === "ar" ? "text-right" : "text-left"}`}
                        style={{ color: `${primary}` }}
                    >
                        {language === "ar" ? "مأكولات راجستان" : "Cuisines of Rajasthan"}
                    </p>
                </div>
                <div
                    className="lg:w-[2%] max-sm:w-[35%]"
                    style={{ borderBottom: "1px solid black" }}
                ></div>
                <div className="lg:w-[50%]" style={{ borderBottom: "1px solid black" }} />
                <div className={`max-sm:hidden ms-10 mt-6 ${language === "ar" ? "hidden" : ""}`}>
    <KeyboardArrowLeftIcon className="rounded-full cursor-pointer" style={{ border: "1px solid black", fontSize: "40px" }} />
    <KeyboardArrowRightIcon className="rounded-full ms-4 cursor-pointer text-[#5B27D5]" style={{ border: `1px solid `, fontSize: "40px" }} />
</div>

            </div>

            {/* Cuisines List Section */}
            <div className={`flex ${language === "ar" ? "flex-row-reverse lg:mr-4 " : ""} space-x-6 max-sm:space-x-2 overflow-x-scroll max-sm:ms-4 mt-10 max-sm:mt-5 ml-20 gap-5 max-sm:gap-2 `}>
                {/* Cuisine Card 1 */}
                <div className="lg:w-[20%] max-sm:w-[83%] flex-none bg-white pb-5">
                    <div>
                        <img className="rounded-2xl" src="https://static.toiimg.com/photo/84442976.cms" alt="Ghewar" />
                    </div>
                    <div>
                        <p className={`font-bold mt-3 ml-1 text-[20px] max-sm:text-[16px] ${language === "ar" ? "text-right" : "text-left"}`}>
                            {language === "ar" ? "جيوار" : "Ghewar"}
                        </p>
                    </div>
                </div>

                {/* Cuisine Card 2 */}
                <div className="lg:w-[20%] max-sm:w-[83%] flex-none bg-white pb-5">
                    <div>
                        <img className="rounded-2xl" src="https://static.toiimg.com/photo/84442976.cms" alt="Ghewar" />
                    </div>
                    <div>
                        <p className={`font-bold mt-3 ml-1 text-[20px] max-sm:text-[16px] ${language === "ar" ? "text-right" : "text-left"}`}>
                            {language === "ar" ? "جيوار" : "Ghewar"}
                        </p>
                    </div>
                </div>

                {/* Add more cuisine cards as needed */}
            </div>
        </div>
    );
}

export default AllCuisines;
