import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ReactHtmlParser from 'html-react-parser';

function Description({key,description,ar_description,language}) {
  const [isExpanded, setIsExpanded] = useState(false);
  // const [description, setDescription] = useState('');
  // const [ar_description, setar_description] = useState('');
  const [fontSize, setFontSize] = useState("16px");
  const [lineHeight, setLineHeight] = useState("1.5");



  const toggleExpand = () => setIsExpanded(!isExpanded);
  const lang = language || '';
  
  
  useEffect(() => {
    // Set description props
    // setDescription(description2);
    // setar_description(ar_description2);

    // Handle responsive font size
    const handleResize = () => {
      if (window.matchMedia("(max-width: 640px)").matches) {
        setFontSize("10px");
        setLineHeight("1.6");
      } else {
        setFontSize("18px");
        setLineHeight("1.5");
      }
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [description,ar_description]);

  const currentDescription = lang === 'ar' ? ar_description : description;

  // Apply styles to both descriptions dynamically
  const styledDescription = currentDescription?.replace(
    /<span[^>]*>/g,
    `<span style="font-size: ${fontSize}; line-height: ${lineHeight};">`
  );

  const sanitizedDescription =
    typeof styledDescription === "string" ? styledDescription : "";

  return (
    <>
    <div key={key}>
    <div className="relative max-w-full " style={{ '--font-size': "40px" }}>
      <div
  className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
    isExpanded ? 'max-h-[10000000px]' : 'max-h-40'
  }`}
>
  <div key={key}
    className="text-[20px] max-sm:text-[13px] leading-6 max-sm:leading-5"
    dangerouslySetInnerHTML={{
      __html: `<span class="override-html">${sanitizedDescription}</span>`,
    }}
  ></div>
</div>
<div
  className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
    isExpanded ? 'max-h-[10000000px]' : 'max-h-40'
  }`}
>
  <div key={key}
    className="text-[20px] max-sm:text-[13px] leading-6 max-sm:leading-5"
    dangerouslySetInnerHTML={{
      __html: `<span class="override-html desc">${sanitizedDescription}</span>`,
    }}
  ></div>
</div>

        {!isExpanded && (
          <div className="absolute bottom-0 left-0 right-0 h-28 bg-gradient-to-t from-white to-transparent pointer-events-none"></div>
        )}
      </div>
      <div className="flex justify-center mt-8 max-sm:mt-4">
      <button
  onClick={toggleExpand}
  className="text-[#804df8] font-medium max-sm:text-[12px]"
>
  {isExpanded
    ? lang === 'ar'  // If the language is Arabic
      ? 'قراءة أقل'    // "Read Less" in Arabic
      : 'Read Less'    // "Read Less" in English
    : lang === 'ar'   // If the language is Arabic
    ? 'قراءة المزيد'  // "Read More" in Arabic
    : 'Read More'}  
  <ChevronRightIcon />
</button>

      </div>
    </div>
   
    </>
  );
}

export default Description;
