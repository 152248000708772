import React, { useState, useEffect } from "react";
import BlogDropdown from "../../../Components/Blogs";
import SearchIcon from "@mui/icons-material/Search";
import WholeCity from "./CityCards";
import { url } from "../../../urlConfig";
import Footer from "../../../Components/Footer";
import NavBar from "../../../Components/NavBar";
import Cookies from "js-cookie";
import PlaceIcon from '@mui/icons-material/Place';
import {
  Box,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
   
function AllCity({ initialData = {},language }) {
  // const storedLang = language || "en"; // Get language from cookies, default to 'en'
  // const lang = language || "";
  const isArabic = language === "ar";
  const [cities, setCities] = useState(initialData?.citie?.data || []);
  const [loading, setLoading] = useState(!!initialData.citie);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [state, setState] = useState("");
  const { categoryId } = useParams();
  // const [language, setLanguage] = useState("English");
  const [stateData, setStateData] = useState(initialData?.state?.data || []);
  const [cityData, setCityData] = useState([]);
  const [city, setCity] = useState(categoryId);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 11;
console.log({serverProsp:initialData.citie})
// import axios from 'axios';
const navigate = useNavigate();
    const handelView = (city) => {
      navigate(`/${language}/trip/${city?.slug.toLocaleLowerCase().replace(/\s+/g, '-')}-tourism-${city?.country?.name.toLocaleLowerCase().replace(/\s+/g, '-')}-${city._id}-ctytsmid`);
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This adds a smooth scroll effect
        });
    };
useEffect(() => {
  if (initialData.citie ===undefined) {
    const fetchCities = async () => {
      try {
        const response = await axios.get(
          `https://admin.hopdays.com/api/all-cities-filtered`,
          {
            params: {
              sortBy,
              state,
              city,
              isPopular: sortBy,
            },
          }
        );
        const data = response.data;
        if (data.status) {
          setCities(response.data.data);
          console.log(data.data);
          // setLoading(true)
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      } 
    };

    fetchCities();
  }
}, [stateData, cities]);

useEffect(() => {
  if (initialData.state ===undefined) {
    const fetchStatesAndCategories = async () => {
      try {
        const stateResponse = await axios.get(`https://admin.hopdays.com/api/states/admin`);
        setStateData(stateResponse.data.data);

       
      } catch (error) {
        console.error("Error fetching states or categories:", error);
      }
    };

    fetchStatesAndCategories();
  }
}, [stateData,cities]);


  const handleClearAll = () => {
    setState("All");
    // setLanguage("English");
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };
  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const filteredCity = cities.filter((city) =>
    isArabic
      ? city.ar_name?.toLowerCase().includes(searchQuery.toLowerCase())
      : city.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  console.log(filteredCity)

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <div className={isArabic ? "text-right" : "text-left"}>
        <NavBar language={language || Cookies.get("lang")}/>
        <div
          className={`lg:ml-40 max-sm:ml-4 mt-8 flex text-[13px] ${
            isArabic ? "flex-row-reverse" : ""
          }`}
        >
          <a href="/" className="hover:text-blue-700">
            {isArabic ? "الرئيسية" : "Home"}
          </a>
          &nbsp; {" > "} &nbsp;
          <p className="font-medium mt-0.5">
            {isArabic ? "جميع المدن" : "All City"}
          </p>
        </div>
        <div
          className={`lg:flex ${
            isArabic ? "lg:mr-40 flex-row-reverse" : "lg:ml-40"
          } max-sm:ml-4 lg:mt-20`}
        >
          <div className="lg:w-[25%]">
            <h2 className="font-semibold text-[30px] mt-2 max-sm:mt-5">
              {isArabic ? "المدن" : "City"}
            </h2>
            <div className="lg:hidden mt-5 justify-between">
              <div>
                <div className="relative">
                  <SearchIcon
                    className={`absolute ${
                      isArabic ? "right-3" : "left-3"
                    } top-1/2 transform -translate-y-1/2 text-gray-400`}
                  />
                  <input
                    type="text"
                    className={`outline-none rounded-lg py-1 ${
                      isArabic ? "pr-10 pl-3" : "pl-10 pr-3"
                    }`}
                    placeholder={
                      isArabic ? "ابحث عن نقطة اهتمام..." : "Search POI..."
                    }
                    style={{ border: `1px solid black` }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <Box
              className=""
              sx={{
                width: 280,
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "12px",
                marginTop: "33px",
              }}
            >
              <FormControl fullWidth sx={{ marginBottom: "16px" }}>
                <InputLabel>{isArabic ? "ترتيب حسب" : "Sort by"}</InputLabel>
                <Select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  label={isArabic ? "ترتيب حسب" : "Sort by"}
                  className="bg-gray-100"
                >
                  <MenuItem value="popular">
                    {isArabic ? "الأكثر شعبية" : "Popular"}
                  </MenuItem>
                  <MenuItem value="Newest">
                    {isArabic ? "الأحدث" : "Newest"}
                  </MenuItem>
                </Select>
              </FormControl>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">
                  {isArabic ? "تصفية" : "Filter"}
                </Typography>
                <Button color="error" onClick={handleClearAll}>
                  {isArabic ? "مسح" : "Clear"}
                </Button>
              </Box>

              <FormControl fullWidth sx={{ marginTop: "16px" }}>
                <InputLabel>{isArabic ? "الدولة" : "State"}</InputLabel>
                <Select
                  value={state}
                  onChange={handleStateChange}
                  label={isArabic ? "الدولة" : "State"}
                  className="bg-gray-100"
                >
                  <MenuItem value="All">{isArabic ? "الكل" : "All"}</MenuItem>
                  {stateData?.map((state, index) => (
                    <MenuItem key={index} value={state._id}>
                      {isArabic ? ` ${state.ar_name}` : state.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ marginTop: "16px" }}>
                <InputLabel>{isArabic ? "الدولة" : "City"}</InputLabel>
                <Select
                  value={city}
                  onChange={handleCityChange}
                  label={isArabic ? "الدولة" : "City"}
                  className="bg-gray-100"
                >
                  <MenuItem value="All">{isArabic ? "الكل" : "All"}</MenuItem>
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city._id}>
                      {isArabic
                        ? ` ${city.ar_name}`
                        : city.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="lg:w-[65%]">
            <div
              className={`lg:hidden font-semibold text-[18px] mt-10 text-gray-400 ${
                isArabic ? "text-right" : "text-left"
              }`}
            >
              {loading
                ? isArabic
                  ? "جارٍ التحميل..."
                  : "Loading..."
                : `${filteredCity.length} ${isArabic ? "نتائج" : "results"}`}
            </div>
            <div
              className={`max-sm:hidden flex justify-between ${
                isArabic ? "flex-row-reverse" : ""
              }`}
            >
              <div className="font-semibold text-[18px] mt-1 text-gray-400">
                {loading
                  ? isArabic
                    ? "جارٍ التحميل..."
                    : "Loading..."
                  : `${filteredCity.length} ${isArabic ? "نتائج" : "results"}`}
              </div>
              <div>
                <div className="relative">
                  <SearchIcon
                    className={`absolute ${
                      isArabic ? "left-3" : "right-3"
                    } top-1/2 transform -translate-y-1/2 text-gray-400`}
                  />
                  <input
                    type="text"
                    className={`outline-none rounded-lg py-1 ${
                      isArabic ? "pr-10 pl-10" : "pl-3 pr-10"
                    }`}
                    placeholder={
                      isArabic ? "ابحث عن نقطة اهتمام..." : "Search POI..."
                    }
                    style={{ border: `1px solid black` }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div
              className={`flex flex-wrap lg:gap-7 lg:mt-10 ${
                isArabic ? "flex-row-reverse lg:mr-10" : ""
              }`}
            >
             {cities?.map((city,index)=>(
                <div key={index} onClick={()=>{handelView(city)}} className="max-sm:w-[50%] lg:w-[31%] rounded-md text-center cursor-pointer flex-none" id='popular-destination-card'>
                <div className='' id='popular-destination-card-image'>
                    <img
                        src={city?.image || "https://media.istockphoto.com/id/639128162/vector/rajasthan-blue-map-on-degraded-background-vector.jpg?s=612x612&w=0&k=20&c=RzKzGJrQeuCe66VJ_RFm_tb_1sviutIBFonlhKHteNg="} // Use dynamic image if available
                        className="rounded-xl lg:w-[400px] lg:h-[350px] max-sm:w-[170px] max-sm:h-[160px]"
                    />
                </div>
                {language === "ar" ?
                    <div className='relative text-center bottom-[80px]' id='popular-destination-card-text'>
                        <p className="text-white font-bold max-sm:text-[16px] lg:text-2xl">{city?.ar_name}</p>
                        <p className="text-white">
                            <PlaceIcon /> {city?.state?.ar_name || "state"} 
                        </p>
                    </div>
                    :
                    <div className='relative text-center bottom-[80px] max-sm:bottom-[50px]' id='popular-destination-card-text'>
                        <p className="text-white font-semibold max-sm:text-xl lg:text-[30px]">{city?.name}</p>
                        <p className="text-white font-light text-[16px] max-sm:text-[12px] lg:mt-1 max-sm:-mt-1">
                            <PlaceIcon /> {city?.state?.name|| "state"}  
                        </p>
                    </div>
                }
            </div>
             ))}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mt-20 space-x-3">
          <button
            onClick={handlePrevPage}
            className="px-2 py-1 rounded hover:bg-gray-200"
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          <span>
            <span className="px-2 py-1 border border-gray-300 rounded">
              {currentPage}
            </span>{" "}
            of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            className="px-2 py-1 rounded hover:bg-gray-200"
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
        </div>
        <Footer language={language}/>
      </div>
    </>
  );
}

export default AllCity;
