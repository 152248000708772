import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import WholeDestination from "../../Components/AllDestination";
import { url } from "../../urlConfig";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";
import Cookies from "js-cookie";
import { Box, MenuItem, FormControl, Select, InputLabel, Typography, Button } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import PlaceIcon from '@mui/icons-material/Place';
import PopularDestinationCard from "../../Components/PopularDestination/PopularDestinationCard";
function AllDestination({initialData={},language}) {
  const isArabic = language === "ar";
  const [states, setStates] = useState(initialData?.state?.data || []);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState('');
  const [country, setCountry] = useState('');
  const { categoryId } = useParams();
  // const [language, setLanguage] = useState('English');
  const [countryData, setCountryData] = useState(initialData?.country?.data || [])
  const [categoryData, setCategoryData] = useState(initialData?.category?.data || [])
  const [category, setCategory] = useState(categoryId)
   const [currentPage, setCurrentPage] = useState(1);
      const totalPages = 11;
      // const lang = language || "";


const navigate = useNavigate();
const handelView = (state) => {
  navigate(`/${language}/travel/${state?.slug.toLocaleLowerCase().replace(/\s+/g, '-')}-tourism-${state?.country?.name.toLocaleLowerCase().replace(/\s+/g, '-')}-${state._id}-sttsmid`);
  window.scrollTo({
      top: 0,
      behavior: 'smooth', // This adds a smooth scroll effect
  });
};
console.log(initialData)
  // First useEffect
  useEffect(() => {
    if(initialData.state===undefined){
      const fetchStates = async () => {
        try {
          setLoading(true);
          const response = await axios.get(`https://admin.hopdays.com/api/all-states-filtered`, {
            params: {
              country: country,
              isPopular: sortBy,
            },
          });
          
            setStates(response.data.data);
         
        } catch (error) {
          console.error("Error fetching states:", error);
          setStates([]);
        } finally {
          setLoading(false); // Stop loading after fetch completes
        }
      };
    
      fetchStates();
    }
    
  }, [states, countryData,categoryData,sortBy, initialData.state?.data]);
  
  // Second useEffect
  useEffect(() => {

    const fetchCountryData = async () => {
      try {
        const response = await axios.get(`https://admin.hopdays.com/api/country`);
        setCountryData(response.data.data);
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };
  
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get(`https://admin.hopdays.com/api/all-category`);
        setCategoryData(response.data.data);
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };
   if(initialData.country===undefined){
    fetchCountryData();
   }else if(initialData.category===undefined){
    fetchCategoryData();
   }
   
    
  }, [states, countryData,categoryData]);
  

  const handleClearAll = () => {
    setCountry('All');
    // setLanguage('English');
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  const handleCategoryChange = (event) => {
    setCategory(event.target.value)
  };

  const filteredState = states?.filter((state) => {
    const name = isArabic ? state?.ar_name : state?.name;
    return name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handlePrevPage = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
};

const handleNextPage = () => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
};


  return (
    <>
      <div className={`${isArabic ? "text-right" : "text-left"}`}>
        <NavBar language={language || Cookies.get("lang")}/>
        <div className={`lg:ml-40 max-sm:ml-4 mt-8 flex text-[13px] ${isArabic ? "lg:ml-0 lg:mr-40 flex-row-reverse" : ""}`}>
          <a href="/" className="hover:text-blue-700">{isArabic ? "الرئيسية" : "Home"}</a> &nbsp; {" > "} &nbsp; <p className="font-medium mt-0.5">{isArabic ? "الوجهات" : "Destinations"}</p>
        </div>
        <div className={`lg:flex ${isArabic ? "lg:mr-40 gap-10 flex-row-reverse" : "lg:ml-40"} max-sm:ml-4 lg:mt-20`}>
          <div className="lg:w-[25%]">
            <h2 className="font-semibold text-[30px] mt-2 max-sm:mt-5">{isArabic ? "الوجهات" : "Destinations"}</h2>
            <div className="lg:hidden mt-5 justify-between">
              <div>
                <div className="relative">
                  <SearchIcon className={`absolute top-1/2 transform -translate-y-1/2 text-gray-400 ${isArabic ? "right-3" : "left-3"}`} />
                  <input
                    type="text"
                    className={`outline-none rounded-lg py-1 ${isArabic ? "pr-10 pl-3" : "pl-10 pr-3"}`}
                    placeholder={isArabic ? "بحث عن دولة..." : "Search State..."}
                    style={{ border: `1px solid black` }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
            </div>
           <Box className="" sx={{ width: 280, border: '1px solid #ccc', borderRadius: '8px', padding: '12px', marginTop: "33px" }}>
                                  <FormControl fullWidth sx={{ marginBottom: '16px' }}>
                                      <InputLabel>{isArabic ? "ترتيب حسب" : "Sort by"}</InputLabel>
                                      <Select
                                          value={sortBy}
                                          onChange={(e) => setSortBy(e.target.value)}
                                          label={isArabic ? "ترتيب حسب" : "Sort by"}
                                          className='bg-gray-100'
                                      >
                                          <MenuItem value="popular">{isArabic ? "الأكثر شعبية" : "Popular"}</MenuItem>
                                          <MenuItem value="Newest">{isArabic ? "الأحدث" : "Newest"}</MenuItem>
          
                                      </Select>
                                  </FormControl>
          
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                      <Typography variant="h6">{isArabic ? "تصفية" : "Filter"}</Typography>
                                      <Button color="error" onClick={handleClearAll}>
                                          {isArabic ? "مسح" : "Clear"}
                                      </Button>
                                  </Box>
          
                                  <FormControl fullWidth sx={{ marginTop: '16px' }}>
                                      <InputLabel>{isArabic ? "الدولة" : "Country"}</InputLabel>
                                      <Select
                                          value={country}
                                          onChange={handleCountryChange}
                                          label={isArabic ? "الدولة" : "Country"}
                                          className='bg-gray-100'
                                      >
                                          <MenuItem value="All">{isArabic ? "الكل" : "All"}</MenuItem>
                                          {countryData?.map((country, index) => (
                                              <MenuItem key={index} value={country._id}>
                                                  {isArabic ? ` ${country.ar_name}` : country.name}
                                              </MenuItem>
                                          ))}
                                      </Select>
                                  </FormControl>
                                  <FormControl fullWidth sx={{ marginTop: '16px' }}>
                                      <InputLabel>{isArabic ? "الدولة" : "Category"}</InputLabel>
                                      <Select
                                          value={category}
                                          onChange={handleCategoryChange}
                                          label={isArabic ? "الدولة" : "Country"}
                                          className='bg-gray-100'
                                      >
                                          <MenuItem value="All">{isArabic ? "الكل" : "All"}</MenuItem>
                                          {categoryData.map((country, index) => (
                                              <MenuItem key={index} value={country._id}>
                                                  {isArabic ? ` ${country.ar_categoryName}` : country.categoryName}
                                              </MenuItem>
                                          ))}
                                      </Select>
                                  </FormControl>
                              </Box>
          </div>
          <div className="lg:w-[65%]">
            <div className={`max-sm:hidden flex justify-between ${isArabic ? "flex-row-reverse" : ""}`}>
              <div className="font-semibold text-[18px] mt-1 text-gray-400">
                {filteredState.length} {isArabic ? "نتائج" : "results"}
              </div>
              <div>
                <div className="relative">
                  <SearchIcon className={`absolute top-1/2 transform -translate-y-1/2 text-gray-400 ${isArabic ? "left-3" : "right-3"}`} />
                  <input
                    type="text"
                    className={`outline-none rounded-lg py-1 ${isArabic ? "pr-10 pl-10" : "pl-10 pr-3"}`}
                    placeholder={isArabic ? "بحث عن دولة..." : "Search State..."}
                    style={{ border: `1px solid black` }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="lg:hidden font-semibold text-[18px] mt-1 text-gray-400">
                {filteredState.length} {isArabic ? "نتائج" : "results"}
              </div>
              {/* {loading ? (
                <div className="text-center text-xl font-semibold text-gray-500">
                  {isArabic ? "جاري تحميل جميع الدول..." : "Loading All States..."}
                </div>
              ) : ( */}
               <div className={`flex flex-wrap lg:gap-7 lg:mt-10 ${isArabic ? "flex-row-reverse" : ""}`}>
      {states?.map((state, index) => (
        <div
          key={index}
          onClick={() => {
            handelView(state);
          }}
          className="max-sm:w-[50%] lg:w-[31%] rounded-md text-center cursor-pointer flex-none"
          id="popular-destination-card"
        >
          <div className="" id="popular-destination-card-image">
            <img
              src={state?.Image}
              className="rounded-xl lg:w-[400px] lg:h-[350px] max-sm:w-[170px] max-sm:h-[160px]"
            />
          </div>
          {language === "ar" ? (
            <div className="relative text-center bottom-[80px]" id="popular-destination-card-text">
              <p className="text-white font-bold max-sm:text-[16px] lg:text-2xl">{state?.ar_name}</p>
              <p className="text-white">
                <PlaceIcon /> {state?.country?.ar_name || "country"}
              </p>
            </div>
          ) : (
            <div
              className="relative text-center bottom-[80px] max-sm:bottom-[50px]"
              id="popular-destination-card-text"
            >
              <p className="text-white font-semibold max-sm:text-xl lg:text-[30px]">
                {state?.name}
              </p>
              <p className="text-white font-light text-[16px] max-sm:text-[12px] lg:mt-1 max-sm:-mt-1">
                <PlaceIcon />
                {state?.country?.name}
              </p>
            </div>
          )}
        </div>
        // <PopularDestinationCard key={index} state={state}/>
      ))}
    </div>
              {/* )} */}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mt-20 space-x-3">
                    <button
                        onClick={handlePrevPage}
                        className="px-2 py-1 rounded hover:bg-gray-200"
                        disabled={currentPage === 1}
                    >
                        &lt;
                    </button>
                    <span>
                        <span className="px-2 py-1 border border-gray-300 rounded">
                            {currentPage}
                        </span>{' '}
                        of {totalPages}
                    </span>
                    <button
                        onClick={handleNextPage}
                        className="px-2 py-1 rounded hover:bg-gray-200"
                        disabled={currentPage === totalPages}
                    >
                        &gt;
                    </button>
                </div>
      </div>
      <Footer language={language}/>
    </>
  );
}

export default AllDestination;
