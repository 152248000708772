import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React from "react";
import Cookies from "js-cookie";

function LatestBlogCard({ blog }) {
    // console.log("Blog Data:", blog);
    const firstImage = blog ? blog.content.match(/<img[^>]+src="([^">]+)"/)?.[1] : null;
    const blogTitle = blog?.title || "No Title";
    const blogExcerpt = blog?.excerpt || "No content available.";
    
    function truncateText(text, maxLength) {
        if (text?.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    }


    return (
        // `/blog/${blog.ID}` id for show blog on react page

        <Link to={`/blog/${blog.ID}`} className="lg:w-[28%] max-sm:w-[80%] max-sm:h-[340px] flex-none mb-5 mt-5 bg-white pb-4 rounded-xl shadow-lg">
            <div>
                <img src={firstImage || "/images/latestBlog.png"} alt="Blog Cover" className="w-full h-48 object-cover rounded-t-xl" />
            </div>
            <div className={`ms-2 mt-4 ${Cookies.get("lang") === "ar" ? "text-right me-2" : "text-left"}`}>
                <p className="font-medium text-[18px] max-sm:text-[20px]">{truncateText(blogTitle,25)}</p>
                <p className="font-medium text-[16px] max-sm:text-[12px] text-gray-500 leading-5 max-sm:leading-3 mt-3 max-sm:mt-3 lg:mb-4"
                    dangerouslySetInnerHTML={{ __html: `<span class="override-htmlaa">${truncateText(blogExcerpt, 110)}</span>` }} />
                <Link to={`/blog/${blog.ID}`}  className="text-[#5B27D5] font-medium text-[18px] max-sm:text-[12px] lg:mt-1 max-sm:mt-2">
                    {Cookies.get("lang") === "ar" ? "قراءة المقال" : "Read Article"}{" "}
                    <KeyboardArrowRightIcon sx={{ fontSize: "18px", transform: Cookies.get("lang") === "ar" ? "rotate(180deg)" : "none" }} />
                </Link>
            </div>
        </Link>
    );
}

export default LatestBlogCard;
