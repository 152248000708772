import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import banner from "../../public/images/banner-hop-days.png"
import NavBar from "../NavBar";
import Footer from "../Footer";
import Banner from "../Banner";

function BlogDetails({language}) {
    const { ID } = useParams();
    const [blog, setBlog] = useState(null);
    const [trendingBlogs, setTrendingBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const siteURL = "balwadaanil0783.wordpress.com";

    useEffect(() => {
        // Fetch blog details
        fetch(`https://public-api.wordpress.com/rest/v1.1/sites/${siteURL}/posts/${ID}`)
            .then((res) => res.json())
            .then((data) => {
                setBlog(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching blog:", error);
                setLoading(false);
            });

        // Fetch trending blogs
        fetch(`https://public-api.wordpress.com/rest/v1.1/sites/${siteURL}/posts/`)
            .then((res) => res.json())
            .then((data) => {
                setTrendingBlogs(data.posts.slice(0, 4)); 
            })
            .catch((error) => console.error("Error fetching trending blogs:", error));
    }, [ID]);

    return (
        <div>
            <NavBar  />
            <div className="container mx-auto lg:p-6 max-sm:p-2 flex flex-col md:flex-row gap-8">
                {/* tranding for large screen */}
                <aside className="w-full ml-10 md:w-1/4 mt-20 max-sm:hidden">
                    <h2 className="text-xl font-semibold mb-4">Trending Blogs</h2>
                    <div className="space-y-4">
                        {trendingBlogs.length > 0 ? (
                            trendingBlogs.map((post) => (
                                <div key={post.ID} className="flex items-center gap-4">
                                    <img
                                        src={banner}
                                        alt={post.title}
                                        className="w-16 h-16 object-cover rounded-md"
                                    />
                                    <p className="text-sm font-medium">{post.title}</p>
                                </div>
                            ))
                        ) : (
                            <p className="text-gray-500">Loading trending blogs...</p>
                        )}
                    </div>
                </aside>

                {/* Main Content */}
                <main className="w-full md:w-3/4 lg:mt-20">
                    {loading ? (
                        <p className="text-lg">Loading...</p>
                    ) : blog ? (
                        <div>
                            {/* Blog Metadata */}
                            <div className="flex justify-between text-[14px] font-semibold text-gray-400 mb-2">
                                <span>Last updated on: {new Date(blog.modified).toDateString()}</span>
                                <span>location {blog.categories[0]}</span>
                            </div>

                            {/* Blog Title */}
                            <h1 className="text-3xl font-bold mb-4">{blog.title}</h1>

                            {/* Feature Image */}
                            {blog.featured_image && (
                                <img
                                    src={blog.featured_image}
                                    alt="Feature"
                                    className="w-full h-96 object-cover rounded-xl  mb-6"
                                />
                            )}

                            {/* Blog Content */}
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: `<span class="override-htmlss">${blog.content}</span>`,
                                }}
                                className="mt-4 text-lg leading-relaxed"
                            />
                        </div>
                    ) : (
                        <p className="text-lg text-red-500">Blog not found.</p>
                    )}
                </main>

                {/* for smaller screen only */}
                <aside className="w-full mt-10 lg:hidden">
                    <h2 className="text-xl font-semibold mb-4">Trending Blogs</h2>
                    <div className="flex w-full gap-4 overflow-scroll pb-2 bg-gradient-to-r from-yellow-50 to-white rounded-lg p-4 pl-0">
                        {trendingBlogs.length > 0 ? (
                            trendingBlogs.map((post) => (
                                <div key={post.ID} className="min-w-[250px] gap-2 flex items-center bg-white shadow-md rounded-lg overflow-hidden">
                                    <img
                                        src={banner}
                                        alt={post.title}
                                        className="w-16 h-16 object-cover rounded-md rounded-r-none"
                                    />
                                    <p className="text-sm font-medium">{post.title}</p>
                                </div>
                            ))
                        ) : (
                            <p className="text-gray-500">Loading trending blogs...</p>
                        )}
                    </div>
                </aside>
            </div>
            <Footer />
        </div>
    );
}

export default BlogDetails;
