import { useEffect, useState } from "react";
import { primary } from "../../AppConfig";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";
import Cookies from "js-cookie";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { url } from "../../urlConfig";
import { Link, useNavigate } from "react-router-dom";
import ReactHtmlParser from "html-react-parser";
import axios from "axios";
function Category({ page, language }) {
  const [subCategory, setSubCategory] = useState(page || []);
  const [description, setDescription] = useState("");
  const [ar_description, setar_description] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log("Fetching popular pages from the API...");

  //   fetch(`${url}/pages/popular`, {
  //     headers: { "Content-Type": "application/json" },
  //   })
  //     .then((res) => {
  //       console.log("Received response from API:", res);
  //       return res.json();
  //     })
  //     .then((response) => {
  //       console.log("Parsed JSON response:", response);

  //       if (response.status === true) {
  //         console.log("API returned a successful response. Setting data...");
  //         setSubCategory(response.data);
  //         console.log("SubCategory state updated:", response.data);
  //       } else {
  //         console.warn("API returned an unsuccessful status:", response.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching categories:", error);
  //     });
  // }, []);
  console.log({ pageeeeee: page })
  useEffect(() => {
    if (page?.length === 0 || page === undefined) {
      const fetchPoiData = async () => {
        try {
          const poiResponse = await axios.get(`https://admin.hopdays.com/api/pages/popular`);
          setSubCategory(poiResponse.data.data);
        } catch (error) {
          console.error("Error fetching data on client-side navigation:", error);
          // setLoadPoi(false);
        }
      };

      fetchPoiData();
    } else {
      setSubCategory(page)
    }
  }, [page])

  let lineHeight = "30px";
  let fontSize = "22px";
  let color = "#FFFFFF";

  // useEffect(() => {
  //   setDescription(props.description);
  //   setar_description(props.ar_description);
  // }, [props]);

  const styledDescription = description?.replace(
    /<span[^>]*>/g,
    `<span style="font-size: ${fontSize}; line-height: ${lineHeight}; color: ${color};">`
  );

  const sanitizedDescription =
    typeof styledDescription === "string" ? styledDescription : "";

  return (
    <>
      <div>
        <div>
          {language === "ar" ? (
            <div className="flex flex-row-reverse me-5 max-sm:me-1 mt-16 max-sm:mt-10">
              <div>
                <p className="flex flex-row-reverse text-[25px] font-light max-sm:text-[16px]">
                  انغمس في نفسك
                </p>
                <p
                  className="lg:text-[40px] max-sm:text-[20px] font-bold mt-2 max-sm:mt-1"
                  style={{ color: `${primary}` }}
                >
                  الفئات الشائعة
                </p>
              </div>
              <div
                className="lg:w-[75%] max-sm:w-[65%]"
                style={{ borderBottom: "1px solid black" }}
              ></div>
            </div>
          ) : (
            <div className="flex lg:ms-20 max-sm:ms-5 mt-16 max-sm:mt-10">
              <div>
                <p className="text-[25px] font-light max-sm:text-[16px]">
                  Immerse Yourself
                </p>
                <p
                  className="lg:text-[40px] max-sm:text-[20px] font-bold mt-2 max-sm:mt-0"
                  style={{ color: `${primary}` }}
                >
                  Trending Categories
                </p>
              </div>
              <div
                className="lg:w-[57%] max-sm:w-[40%]"
                style={{ borderBottom: "1px solid black" }}
              ></div>
            </div>
          )}
        </div>

        {/* For Larger Screens */}
        <div className="max-sm:hidden">
          <div
            className={`flex overflow-x-scroll lg:w-[92%] lg:ms-20 max-sm:ms-5 mt-10 gap-5 ${language === "ar" ? "flex-row-reverse" : ""
              }`}
          >
            {subCategory?.map((category2, index) => (
              <Link onClick={()=>{Cookies.set('catgeoryId', category2.location, { expires: 7 });}} to={{
                pathname: `/${language || "en"}/${category2.locationName?.toLowerCase().replace(/\s+/g, '-')}/${category2?.target?.subCategory2Name
                  ? category2?.target?.slug?.toLowerCase().replace(/\s+/g, '-')
                  : category2?.target?.slug?.toLowerCase().replace(/\s+/g, '-')
                  }-${category2?.locationName?.toLowerCase().replace(/\s+/g, '-')}-${category2?.CountryName?.toLowerCase().replace(/\s+/g, '-')}-${category2?.target?._id}-lpidon`,
                state: { categoryId:"hello" }, // Pass the entire category object as state
              }} key={index}>
                <div>
                  <div
                    className="lg:w-[662px] lg:h-[420px] rounded-lg object-cover cursor-pointer"
                    id="trending-cat-card"
                  >
                    <img
                      className="lg:w-[662px] lg:h-[420px] max-sm:h-[160px] rounded-lg object-cover"
                      id="trending-cat-card-img"
                      src={category2?.target?.image || "https://via.placeholder.com/662x420"}
                      alt={category2?.target?.categoryName || "Category"}
                    />
                    <div
                      className={`relative bottom-7 z-10 px-5 ${language === "ar" ? "text-right" : "text-left"
                        }`}
                      id="trending-cat-text"
                    >
                      <h1 className="text-white font-semibold lg:text-[25px]">
                        {language === "ar"
                          ? category2?.target?.ar_subCategoryName || category2?.target?.ar_categoryName || "اسم الفئة"
                          : category2?.target?.subCategoryName || category2?.target?.categoryName || "Category Name"}
                      </h1>
                      <p className="hidden paragraph text-white lg:text-[22px] mt-6">
                        {language === "ar"
                          ? ReactHtmlParser(
                            `<span class="override-html">${category2?.target?.ar_description || "اكتشف المزيد عن هذه الفئة."}</span>`
                          )
                          : ReactHtmlParser(
                            `<span class="override-html">${category2?.target?.description || "Discover more about this category."}</span>`
                          )}
                      </p>

                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>

        {/* For Smaller Screens */}
        <div className="lg:hidden ml-5">
          <div
            className={`flex overflow-x-scroll hover me-2 mt-5 gap-2 max-sm:gap-3 ${language === "ar" ? "flex-row-reverse" : ""
              }`}
          >
            {subCategory.map((category, index) => (
              <Link  onClick={()=>{localStorage.setItem("locationId",category.location)}} to={{
                pathname: `/${language || "en"}/${category.locationName?.toLowerCase().replace(/\s+/g, '-')}/${category?.target?.subCategoryName
                  ? category?.target?.slug?.toLowerCase().replace(/\s+/g, '-')
                  : category?.target?.slug?.toLowerCase().replace(/\s+/g, '-')
                  }-${category?.locationName?.toLowerCase().replace(/\s+/g, '-')}-${category?.CountryName?.toLowerCase().replace(/\s+/g, '-')}-${category.target._id}-lpidon`,
                state: { category }, // Pass the entire category object as state
              }} key={index}>
                <div>
                  <div className="w-[200px] max-sm:w-[325px] max-sm:h-[200px] rounded-lg object-cover cursor-pointer group" id="trending-cat-card-2">
                    <img
                      className="max-sm:h-[200px] max-sm:w-[500px] w-[200px] rounded-lg object-cover"
                      src={category.target?.image || "https://via.placeholder.com/662x420"}
                      alt={category.target?.categoryName || "Category"}
                    />
                    <div
                      className={`relative bottom-7 z-10 px-5 ${language === "ar" ? "text-right" : "text-left"
                        }`}
                      id="trending-cat-text-2"
                    >
                      <h1 className="text-white font-semibold lg:text-[25px] ">
                      {language === "ar"
                          ? category?.target?.ar_subCategoryName || category?.target?.ar_categoryName || "اسم الفئة"
                          : category?.target?.subCategoryName || category?.target?.categoryName || "Category Name"}
                      </h1>
                      <p
                        className="hidden group-hover:block paragraph text-white lg:text-[22px] mt-2 transition-opacity duration-300 opacity-0 group-hover:opacity-100"
                      >
                        {language === "ar"
                          ? ReactHtmlParser(
                            `<span class="override-html">${category.target?.ar_description || "اكتشف المزيد عن هذه الفئة."}</span>`
                          )
                          : ReactHtmlParser(
                            `<span class="override-html">${category.target?.description || "Discover more about this category."}</span>`
                          )}
                      </p>
                    </div>
                  </div>

                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
