import { primary } from "../../AppConfig";
import LatestBlogCard from "./LatestBlogCard";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from "react-router-dom";

function LatestBlogs({ language }) {
    const navigate = useNavigate();
    const [blogs, setBlogs] = useState([]);
    const siteURL = "balwadaanil0783.wordpress.com"; 

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch(`https://public-api.wordpress.com/rest/v1.1/sites/${siteURL}/posts/`);
                const data = await response.json();
                // console.log("Fetched Blogs:", data);
                if (data.posts) {
                    setBlogs(data.posts); 
                }
            } catch (error) {
                console.error("Error fetching blogs:", error);
            }
        };

        fetchBlogs();
    }, []);

    const extractFirstImage = (content) => {
        const match = content.match(/<img[^>]+src="([^">]+)"/);
        return match ? match[1] : null;
    };

    const handleExploreMore = () => {
        navigate("/blogs");
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div className="bg-gradient-to-b from-white via-[#f4f1cf] to-white pb-5">
            <div>
                {language === "ar" ? (
                    <div className="flex flex-row-reverse lg:me-5 max-sm:me-1 mt-10">
                        <div>
                            <p className="text-[25px] text-right font-light mt-5 max-sm:text-[16px]">اقرأ المزيد</p>
                            <p className="lg:text-[40px] max-sm:text-[20px] font-bold mt-2 max-sm:mt-1" style={{ color: `${primary}` }}>أحدث المدونات</p>
                        </div>
                        <div className="lg:w-[60%] max-sm:w-[50%]" style={{ borderBottom: "1px solid black" }}></div>
                    </div>
                ) : (
                    <div className="flex lg:ms-20 max-sm:ms-5 mt-10">
                        <div>
                            <p className="text-[25px] font-light max-sm:text-[16px] mt-5">Read More</p>
                            <p className="lg:text-[40px] max-sm:text-[20px] font-bold mt-2 max-sm:mt-1" style={{ color: `${primary}` }}>Latest Blogs</p>
                        </div>
                        <div className="lg:w-[69%] max-sm:w-[60%]" style={{ borderBottom: "1px solid black" }}></div>
                        <div className="max-sm:hidden ms-10 mt-10">
                            <KeyboardArrowLeftIcon className="rounded-full cursor-pointer" style={{ border: "1px solid black", fontSize: "40px" }} />
                            <KeyboardArrowRightIcon className="rounded-full ms-4 cursor-pointer" style={{ border: `1px solid ${primary}`, fontSize: "40px" }} />
                        </div>
                    </div>
                )}
            </div>

            <div className={`flex lg:w-[90%] max-sm:w-full max-sm:ml-4 ${language === "ar" ? "flex-row-reverse" : ""} overscroll-x-contain overflow-x-scroll mt-5 max-sm:mt-2 lg:ms-20 max-sm:me-5 gap-5`}>
                {blogs.map((blog) => (
                    <LatestBlogCard key={blog.ID} blog={blog} />
                ))}
            </div>

            <div className={`lg:ms-20 max-sm:ms-5 max-sm:-mt-1 ${language === "ar" ? "flex flex-row-reverse lg:me-20 max-sm:me-5" : ""}`}>
                <button
                    onClick={handleExploreMore}
                    className="border-2 border-black rounded-full mt-1 px-7 py-1 max-sm:text-[14px] max-sm:px-3 max-sm:py-0 hover:bg-[#9747FF] hover:border-white hover:text-white font-semibold"
                >
                    {language === "ar" ? "قراءة الكل" : "Read All"}
                    <ChevronRightIcon sx={{ fontSize: "16px" }} />
                </button>
            </div>
        </div>
    );
}

export default LatestBlogs;
