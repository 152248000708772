import React, { useState, useEffect } from "react";
import BlogDropdown from "../../Components/Blogs";
import SearchIcon from '@mui/icons-material/Search';
import PoiCard from "../../Components/Poi/poiCard";
import { Box, MenuItem, FormControl, Select, InputLabel, Typography, Button } from '@mui/material';
import { url } from "../../urlConfig";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AttractionCard from "../../Components/Attractions/AttractionCard";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";
import Cookies from "js-cookie";
import axios from "axios";
import Category from "../../Components/Category";
function AllPages({ initialData = {}, language }) {
  const [pois, setPois] = useState(initialData?.page?.data || []);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const { location, slug } = useParams();


  const isArabic = language === "ar";
  const lang = language
  const location2 = useLocation();
  // const category = location2?.state?.category;
  const category = slug.match(/([a-f0-9]{24})/);
  console.log(initialData)
  const navigate = useNavigate()
  // const  {categoryId}  = location2.state || {}
  // console.log({"hello Category":localStorage.getItem("locationId")})
  let locationId = Cookies.get('locationId');

  //   useEffect(()=>{
  //     window.location.reload()
  //   },[])
  // useEffect(() => {
  //     const fetchPois = async () => {
  //         try {
  //             const response = await fetch(`${url}/page/single/${pageId}`);
  //             const result = await response.json();


  //             console.log("Fetched data:", result);

  //             if (result.status) {
  //                 setPois(result.data);
  //             } else {
  //                 console.error("Failed to fetch POIs");
  //             }
  //         } catch (error) {
  //             console.error("Error fetching POIs:", error);
  //         } finally {
  //             setLoading(false);
  //         }
  //     };

  //     fetchPois();
  // }, [pageId]);

  function truncateText(text, wordLimit) {
    if (!text) return "";
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  }

  useEffect(() => {
    if (initialData?.page === undefined) {
      // alert("hiii")
      const fetchPois = async () => {
        try {
          const response = await axios.get(`https://admin.hopdays.com/api/all_pois/${language}`, {
            params: {
              category: category[0],
              location: location
            },
          });


          setPois(response.data.data);
          console.log(response.data.data);

        } catch (error) {
          console.error("Error fetching POIs:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchPois();
    } else {
      setPois(initialData?.page?.data)
      setLoading(false);
    }

  }, [pois]);


  const filteredPois = pois?.filter((pois) =>
    pois?.title?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className={isArabic ? "text-right" : "text-left"}>
      <NavBar language={language || Cookies.get("lang")} />
      {/* <div className={`lg:ml-40 max-sm:ml-4 mt-8 flex text-[13px] ${isArabic ? "lg:ml-0 lg:mr-40 flex-row-reverse" : ""}`}>
                {isArabic ? (
                    <>
                        <span>كل النقاط المثيرة للاهتمام</span> {" < "} <span>الرئيسية</span>
                    </>
                ) : (
                    <>
                        <span>Home</span> {" > "} <span>All POI</span>
                    </>
                )}
                
            </div> */}


      <div className={`lg:flex ${isArabic ? "flex-row-reverse lg:mr-40 max-sm:mr-4" : " max-sm:ml-4"} `}>

        <div className="lg:w-[25%]">


          <div className="lg:hidden flex justify-between">
            <div className="mt-5">
              <div className="relative">
                <SearchIcon className={`absolute ${isArabic ? "left-3" : "right-3"} top-1/2 transform -translate-y-1/2 text-gray-400`} />
                <input
                  type="text"
                  className={`outline-none rounded-lg py-1 ${isArabic ? "pr-3 pl-10" : "pl-3 pr-10"}`}
                  placeholder={isArabic ? "ابحث عن نقطة الاهتمام..." : "Search POI..."}
                  style={{ border: `1px solid black` }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="lg:w-[200%]">
          <div className={`max-sm:hidden    ${isArabic ? "flex-row-reverse" : ""}`}>

            <div>
              <h2 className="font-semibold text-[25px] mt-10 mb-5 max-sm:hidden">
                {isArabic
                  ? `${category.ar_name} في ${category.ar_location}`
                  : `${category.name} in ${category.location}`}
              </h2>
              <div className="relative">
                {/* <SearchIcon className={`absolute ${isArabic ? "left-3" : "right-3"} top-1/2 transform -translate-y-1/2 text-gray-400`} /> */}
                <input
                  type="text"
                  className={`outline-none rounded-lg py-1 ${isArabic ? "pr-3 pl-10" : "pl-3 pr-10"}`}
                  placeholder={isArabic ? "ابحث عن نقطة الاهتمام..." : "Search POI..."}
                  style={{ border: `1px solid black` }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="font-semibold text-[18px] mt-5 text-gray-400">
              {filteredPois.length} {isArabic ? "نتائج" : "results"}
            </div>
          </div>
          <div className={`mt-5 font-semibold lg:hidden ${isArabic ? "text-right" : ""}`}>{isArabic ? "جميع النقاط المثيرة للاهتمام:" : "All POIs:"}</div>
          <div className={`lg:hidden font-semibold text-[18px] mt-1 text-gray-400 ${isArabic ? "text-right" : ""}`}>
            {filteredPois.length} {isArabic ? "نتائج" : "results"}
          </div>
          {loading === true ? (
            <div className="text-center mt-10 text-xl font-semibold text-gray-500">
              {isArabic ? "جارٍ تحميل جميع النقاط المثيرة للاهتمام..." : "Loading all POIs..."}
            </div>
          ) : (
            <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  mt-6 ${language == "ar" ? "flex-row-reverse" : ""}`}>
              {filteredPois?.length > 0 ? (
                filteredPois.map((i) => {
                  // Calculate `city2` and `url2` inside the map
                  const city2 = i?.cityData?.name?.toLocaleLowerCase().replace(/\s+/g, '-');
                  const url = `${i.slug}-${i?.cityData?.name}-${i?.countryData?.name}-poi-${i._id}`;
                  const url2 = url.toLowerCase().replace(/\s+/g, '-');
                  const image = i.images[0]
                  const isArabic = language == "ar";
                  const navigateUrl = isArabic
                    ? `/ar/place/${city2}/${url2}`
                    : `/en/place/${city2}/${url2}`;
                  const title = isArabic ? i.ar_title : i.title;
                  const stateName = isArabic ? i?.stateData?.ar_name : i?.stateData?.name;
                  const description = isArabic
                    ? truncateText(i.ar_shortDescription, 130)
                    : truncateText(i.shortDescription, 130);

                  return (
                    <div
                      key={i._id}
                      className="lg:w-[404px] max-sm:w-[90%] flex-none mb-5 cursor-pointer"
                      onClick={() => {
                        navigate(navigateUrl);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <div>
                        <img
                          loading="lazy"
                          src={image}
                          alt={`${title} image`}
                          className="lg:w-[404px] lg:h-[280px] max-sm:w-[90%] max-sm:h-[200px] rounded-md hover:shadow-xl"
                        />
                      </div>
                      <div className="py-4">
                        <p className="text-[20px] max-sm:text-[15px]">
                          <b className="text-[22px] max-sm:text-[20px]">{title}, </b>
                          {stateName}
                        </p>
                        <div
                          className="lg:mt-5 max-sm:w-[90%] max-sm:mt-3 text-[17px] max-sm:text-[10px] lg:leading-5 max-sm:leading-3"
                          dangerouslySetInnerHTML={{
                            __html: `<span class="override-html">${truncateText(description, 30)}</span>`,
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>
                  {language === "ar"
                    ? "لا توجد نقاط اهتمام متاحة."
                    : "No Points of Interest available."}
                </p>
              )}
            </div>

          )}
        </div>
      </div>
      <Footer language={language} />
    </div>
  );
}

export default AllPages;
