import React, { useEffect, useState } from "react";
import CustomCarousel from "./CustomSlider";

export default function Slider(props) {
  const [images, setImages] = useState([]);
  
  useEffect(() => {
    setImages(props.images);
    console.log(props.images)
  }, [props]);

  return (
    <div className="App rounded-md">
      <CustomCarousel>
        {images?.map((image, index) => {
          return <img key={index} loading="lazy" src={image} alt={`Slide ${index}`} className="rounded-md lg:w-[793px]  max-sm:w-[320px] max-sm:h-[190px]" />;
        })}
      </CustomCarousel>
    </div>
  );
}
