import Attractions from "../../Components/Attractions";
import Banner from "../../Components/Banner";
import Category from "../../Components/Category";
import LatestBlogs from "../../Components/LatestBlogs";
// import PopularDestination from "../../Components/PopularDestination";
import { useTranslation } from "react-i18next"
import PopularDestination from "../../Components/PopularDestination";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../urlConfig";
function Home({initialData,language}) {
    //  console.log({real:initialData.page})
    const [category,setCategory]=useState(initialData?.popular?.data)
    const [poi,setPoi]=useState(initialData?.poi?.data)
    const [loadPoi,setLoadPoi]=useState(!!poi);
    const [loadPopular,setLoadPopular]=useState(!!poi);
    const [page,setPage]=useState(initialData?.page?.data)
    useEffect(()=>{
    if (initialData.popular===undefined ) {
        console.log("hello")
      const fetchPoiData = async () => {
        try {
          const categoryResponse = await axios.get(`${url}/all-category`);
          setCategory(categoryResponse.data.data);
          console.log(categoryResponse.data)
          setLoadPopular(true);
        } catch (error) {
          console.error("Error fetching data on client-side navigation:", error);
          setLoadPopular(false);
        }
      };

      fetchPoiData();
    }else{
        setCategory(initialData.popular.data)
    }
  }, [ category]);
  useEffect(()=>{
    if (initialData.poi===undefined ) {
        const fetchPoiData = async () => {
          try {
            const poiResponse = await axios.get(`${url}/get-all-pois/${language}`);
            setPoi(poiResponse.data.data);
            console.log(poiResponse.data)
            
           
            setLoadPoi(true);
          } catch (error) {
            console.error("Error fetching data on client-side navigation:", error);
            setLoadPoi(false);
          }
        };
  
        fetchPoiData();
      } else{
        setPoi(initialData.poi.data)
      }
  },[poi])


    return ( 
        <>
        
        <Banner language={language}/>
        <PopularDestination stateData={category} load={loadPopular} language={language}/>
        <Attractions poiData={poi} load={loadPoi} language={language}/>
        {/* <Category page={initialData?.page?.data} language={language}/> */}
        {/* <LatestBlogs/> */}
        
        </>
     );
}

export default Home;