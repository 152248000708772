// import React from "react";
// import banner from "../../public/images/banner-hop-days.png"
// import Cookies from "js-cookie";


// function Banner({language}) {
//   // const language = Cookies.get("lang");
//   // const lang= localStorage.getItem("lang")
//   console.log(language)
//     return (  
//         <>
//      <div className="lg:w-full">
//       <div>
//         <img src={banner} className="lg:h-[70%] max-sm:h-[400px]"/>
//       </div>
//       <div
//       className={`relative lg:bottom-[300px] lg:p-10 max-sm:bottom-[100px] lg:ms-14 max-sm:ms-5 text-white ${
//         language === "ar" ? "flex-row-reverse text-right max-sm:mr-5" : "flex-row text-left"
//       }`}
//     >
//       <div>
//         <h1
//           className="lg:text-[60px] max-sm:text-[40px] font-md"
//           style={{ fontFamily: "inter" }}
//         >
//           {language == "ar" ? "جنوب أفريقيا" : "South Africa"}
//         </h1>
//         <p className="mt-2 text-[20px] max-sm:text-[18px] font-normal">
//           {language == "ar" ? "رحلة العمر" : "A Journey of a Lifetime"}
//         </p>
//         <button className="text-[20px] font-medium border-2 border-white rounded-full lg:px-6 max-sm:hidden mt-3">
//           {language === "ar" ? "استكشاف" : "Explore"}
//         </button>
//       </div>
//     </div>
//      </div>
//         </>
//      );
// }

// export default Banner;


import React, { useEffect, useRef } from "react";
import Cookies from "js-cookie";

function Banner() {
  const scriptContainerRef = useRef(null);

  // Get language from cookies or localStorage
    // Get language from cookies or localStorage (client-side only)
    const language =
    Cookies.get("lang") ||
    (typeof window !== "undefined" && localStorage.getItem("lang")) ||
    "en";

  useEffect(() => {
    // Determine locale based on language
    const locale = language === "ar" ? "ar" : "en";

    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://tp.media/content?currency=usd&trs=208570&shmarker=165481.home-page&combine_promos=100_7879&show_hotels=false&powered_by=false&locale=${locale}&searchUrl=compare.hopdays.com%2Fhotels&primary_override=%23FACD00&color_button=%23641DD8&color_icons=%23030A0Eff&secondary=%23FFFFFF&dark=%23262626&light=%23FFFFFF&special=%23C4C4C4&color_focused=%23FACD00&border_radius=9&no_labels=true&plain=true&promo_id=7873&campaign_id=101`;
    script.charset = "utf-8";

    // Append script inside the container div
    if (scriptContainerRef.current) {
      scriptContainerRef.current.innerHTML = ""; // Clear previous script
      scriptContainerRef.current.appendChild(script);
    }

    return () => {
      // Cleanup: Remove script when component unmounts
      if (scriptContainerRef.current) {
        scriptContainerRef.current.innerHTML = "";
      }
    };
  }, [language]); // Re-run effect when language changes

  return (
    <div className="w-full p-4 mb-40 flex justify-center items-center">
      <div ref={scriptContainerRef} className="w-full"></div>
    </div>
  );
}

export default Banner;

