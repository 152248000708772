import React, { useState, useEffect } from 'react';
import { Box, MenuItem, FormControl, Select, InputLabel, Typography, Button } from '@mui/material';
import { primary } from '../../AppConfig';

function BlogDropdown({ states = [], onFilterChange, isArabic }) {
  const [sortBy, setSortBy] = useState('Popular');
  const [state, setState] = useState('All');
  const [country, setCountry] = useState('All');
  const [countries, setCountries] = useState([]); // Dynamic countries list
  const [stateOptions, setStateOptions] = useState([]); // Dynamic states for selected country

  const handleClearAll = () => {
    setState('All');
    setCountry('All');
    setStateOptions([]);
    setSortBy('Popular');
    onFilterChange({ state: 'All', country: 'All', sortBy: 'Popular' });
  };

  const handleCountryChange = async (event) => {
    const selectedCountry = event.target.value;
    setCountry(selectedCountry);
    setState('All');

    if (selectedCountry === 'All') {
      setStateOptions([]);
      onFilterChange({ state: 'All', country: 'All', sortBy });
      return;
    }

    try {
      const response = await fetch(`${primary.url}/states?country=${selectedCountry}`);
      const data = await response.json();
      if (data.status) {
        setStateOptions(data.states || []);
        onFilterChange({ state: 'All', country: selectedCountry, sortBy });
      }
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  const handleStateChange = (event) => {
    const selectedState = event.target.value;
    setState(selectedState);
    onFilterChange({ state: selectedState, country, sortBy });
  };

  const handleSortChange = (event) => {
    const selectedSort = event.target.value;
    setSortBy(selectedSort);
    onFilterChange({ state, country, sortBy: selectedSort });
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(`${primary.url}/country`);
        const data = await response.json();
        if (data.status) {
          setCountries(data.data || []);
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  return (
    <Box
      sx={{
        width: 280,
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '12px',
        marginTop: '33px',
        direction: isArabic ? 'rtl' : 'ltr',
      }}
    >
      <FormControl fullWidth sx={{ marginBottom: '16px' }}>
        <InputLabel>{isArabic ? 'الترتيب حسب' : 'Sort by'}</InputLabel>
        <Select
          value={sortBy}
          onChange={handleSortChange}
          label={isArabic ? 'الترتيب حسب' : 'Sort by'}
          className="bg-gray-100"
        >
          <MenuItem value="Popular">{isArabic ? 'شائع' : 'Popular'}</MenuItem>
          <MenuItem value="Newest">{isArabic ? 'الأحدث' : 'Newest'}</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">{isArabic ? 'التصفية' : 'Filter'}</Typography>
        <Button color="error" onClick={handleClearAll}>
          {isArabic ? 'مسح' : 'Clear'}
        </Button>
      </Box>

      <FormControl fullWidth sx={{ marginTop: '16px' }}>
        <InputLabel>{isArabic ? 'دولة' : 'Country'}</InputLabel>
        <Select
          value={country}
          onChange={handleCountryChange}
          label={isArabic ? 'دولة' : 'Country'}
          className="bg-gray-100"
        >
          <MenuItem value="All">{isArabic ? 'الكل' : 'All'}</MenuItem>
          {countries.map((c) => (
            <MenuItem key={c._id} value={c.name}>
              {isArabic ? c.ar_name : c.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginTop: '16px' }}>
        <InputLabel>{isArabic ? 'ولاية' : 'State'}</InputLabel>
        <Select
          value={state}
          onChange={handleStateChange}
          label={isArabic ? 'ولاية' : 'State'}
          className="bg-gray-100"
          disabled={stateOptions.length === 0}
        >
          <MenuItem value="All">{isArabic ? 'الكل' : 'All'}</MenuItem>
          {stateOptions.map((s) => (
            <MenuItem key={s._id} value={s.name}>
              {isArabic ? s.ar_name : s.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default BlogDropdown;
