import React, { useEffect, useState } from "react";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { primary, url } from "../../urlConfig";
import CardList2 from "../../Components/Shimmer/card2";
import Lines from "../../Components/Shimmer/Lines";
import CardList from "../../Components/Shimmer/Cards";
import Cookies from "js-cookie";
import AttractionCard from "../../Components/Attractions/AttractionCard";
import AllCuisines from "../../Components/cuisines";
import LatestBlogs from "../../Components/LatestBlogs";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";
import ReactHtmlParser from 'html-react-parser';
import ContentLoader from "react-content-loader";
import axios from "axios";
import Category from "../../Components/Category";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import { Category } from "@mui/icons-material";
function CityView({ initialData = {}, language }) {
  const navigate = useNavigate();
  const [category, setCategory] = useState(initialData?.page?.data || []);
  const [cities, setCities] = useState([]);
  const [cityData, setCityData] = useState(initialData.city?.data?.city || {});
  const [load, setLoad] = useState(false);
  const [poi, setPoi] = useState(initialData.city?.data?.pois || []);
  const [catgeory, setcategory] = useState(initialData.page?.data || []);
  const [description, setDescription] = useState('');
  const [ar_description, setar_description] = useState('');

  // const { cityId } = useParams();
  const location =useLocation()
  const cityId = location.pathname.match(/([a-f0-9]{24})/); // Match 24-character MongoDB ObjectID
  console.log(initialData)
  useEffect(() => {
    if (!cityId) {
      console.error("cityId is not defined");
      return;
    }

    const fetchCityData = async () => {

      if (initialData.city === undefined) {
        try {

          const response = await axios.get(`https://admin.hopdays.com/api/single-city/${cityId[0]}`);





          setCityData(response.data.data.city);  // Set city data
          setPoi(response.data.data.pois);  // Set points of interest (POI) with fallback to empty array
          // setCategory(categories);
          setLoad(false)
        } catch (error) {
          console.error("Fetch Error:", error);
        }
      } else {
        setLoad(false)
      }


    };

    fetchCityData();
  }, [cityData, poi,]);


  // useEffect(() => {
  //   if(initialData.page ===undefined){
  //  try {
  //   const response= axios.get('http://localhost:8080/api/pages/popular')
  //   setCategory(response.data.data)
  //   console.log("HTTP Response:", response.data.data);
  //  } catch (error) {
  //   console.error("Fetch Error:", error);
  //  }



  //   }


  // }, [cityData,poi,category]);

  let lineHeight = "30px"
  let fontSize = "20px"
  let color = "#FFFFFF"
  useEffect(() => {
    setDescription(cityData.description);
    setar_description(cityData.ar_description);
  }, [cityData]);
  const styledDescription = description?.replace(
    /<span[^>]*>/g,
    `<span style="font-size: ${fontSize}; line-height: ${lineHeight}; color: ${color};">`
  );
  const sanitizedDescription = typeof styledDescription === 'string' ? styledDescription : '';


  const categoryId = "all"
  const handelExploreMore = () => {
    navigate(`/${language}all-poi/${categoryId}`); window.scrollTo({
      top: 0,
      behavior: 'smooth', // This adds a smooth scroll effect
    })
  };





  function truncateText(text, wordLimit) {
    if (!text) return "";
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  }

  return (
    <>
      <div>
        <div className="w-full">
          <NavBar language={language || Cookies.get("lang")}/>
          {/* {load === false ? (
            <ContentLoader
              speed={3}
              width="100%"
              height="750"
              viewBox="0 0 100% 531"
              backgroundColor="#ebedeb"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="15" rx="4" ry="4" width="100%" height="30" />
              <rect x="0" y="45" rx="4" ry="4" width="100%" height="20" />
              <rect x="0" y="75" rx="4" ry="4" width="100%" height="30" />
              <rect x="0" y="110" rx="4" ry="4" width="100%" height="30" />
              <rect x="0" y="150" rx="4" ry="4" width="100%" height="30" />
              <rect x="0" y="190" rx="4" ry="4" width="100%" height="30" />
              <rect x="0" y="230" rx="4" ry="4" width="100%" height="30" />

            </ContentLoader>
          ) : ( */}
          <div>
            <div>
              <img className="h-[531px] max-sm:h-[400px] w-full" src={cityData?.image} />
            </div>

            <div
              className={`relative lg:bottom-[500px] max-sm:bottom-[100px] lg:ms-20 max-sm:ms-4 text-white ${language === "ar" ? "text-right" : "text-left"}`}
            >
              <p
                className={`mb-80 text-[13px] flex font-medium ${language === "ar" ? "rtl mr-5" : "text-left"} max-sm:hidden`}
              >
                {language === "ar"
                  ? `${cityData?.ar_name || "اسم المدينة"} > ${cityData?.state?.ar_name || "الولاية"} > ${cityData?.country?.ar_name || "الدولة"}`
                  : `${cityData?.country?.name || "Country"} > ${cityData?.state?.name || "State"} > ${cityData?.name || "City Name"}`}
              </p>

              <h1
                className={`lg:text-[70px] max-sm:text-[40px] font-medium ${language === "ar" ? "text-right mr-5" : "text-left"}`}
                style={{ fontFamily: "inter" }}
              >
                {language === "ar" ? cityData?.ar_name || "اسم المدينة" : cityData?.name || "City Name"}
              </h1>

              <p
                style={{ marginBottom: "-90px" }}
                className={`mt-2 text-[20px] ${language === "ar" ? "text-right mr-5" : "text-left"}`}
              >
                {language === "ar"
                  ? `${cityData?.state?.ar_name || "الولاية"} , ${cityData?.country?.ar_name || "الدولة"}`
                  : `${cityData?.state?.name}, ${cityData?.country?.name || "country"}`}
              </p>
            </div>
          </div>
          {/* // )} */}



        </div>


        <div
          className={`flex max-sm:flex-col ${language === "ar" ? "flex-row-reverse mr-4" : "flex-row"
            } max-sm:-mt-14 lg:-mt-96`}
        >


          <div className="text-center flex justify-center max-sm:w-[98%] ml-20 max-sm:ms-0  mt-5 items-center gap-28   max-sm:mt-16 w-[50%] ">
            <div
              className={`${language === "ar" ? "text-right" : "text-center"
                }`}
            >
              <h1 className="italic max-sm:text-[20px] text-[40px] max-sm:font-medium">
                {load === true ? (
                  <Lines width={"400px"} />
                ) : (
                  language === "ar"
                    ? truncateText(cityData?.ar_blurb, 5)
                    : truncateText(cityData?.blurb, 5)
                )}
              </h1>


              <p className="text-[20px] max-sm:text-[13px] mt-10 max-sm:mt-1 leading-6 max-sm:leading-4 italic max-sm:ms-1">
                {language === "ar"
                  ? ReactHtmlParser(`<span className="override-html">${truncateText(cityData?.ar_description, 50)}</span>`)
                  : ReactHtmlParser(`<span className="override-html">${truncateText(cityData?.description, 50)}</span>`)}
              </p>
            </div>

            {load === true ? null : (
              language === "en" && (
                <div className="max-sm:hidden border-l-2 border-[#cacbcc] h-40 mt-4 "></div>
              )
            )}

          </div>
          {load === true ? null : (
            language === "ar" && (
              <div className="border-l-2 border-[#cacbcc] h-40 mt-4 ml-2 max-sm:hidden "></div>
            )
          )}

          {load === true ? (
            <div className="mt-8">
              <Lines width={"400px"} />
            </div>

          ) : (language === "ar" ?
            <div
              className={`flex overflow-x-scroll items-center ml-5 mt-5 max-sm:mt-10 gap-5 max-sm:gap-3 w-[50%] max-sm:w-[100%] max-sm:ms-4  flex-row-reverse`}
            >

              
                <div
                  
                  className="lg:w-[60%] max-sm:w-[300px] max-sm:ml-1 flex-none bg-[#FFFEEF] lg:h-[160px] max-sm:h-[120px] rounded-xl shadow-md"
                >
                  <div className="ms-2 mt-1">
                    <p className="mt-5 text-[18px] max-sm:text-[13px] text-end ">
                      {/* {cityData?.ar_tips} */}
                      {ReactHtmlParser(`<span className="override-html">${truncateText(cityData?.ar_tips, 50)}</span>`)}
                    </p>
                    {/* <p className="mt-8 max-sm:mt-4 text-center items-center text-[18px] max-sm:text-[10px]">
                      {language === "ar"
                        ? truncateText(tip.description, 22)
                        : truncateText(tip.description, 22)}
                    </p> */}
                  </div>
                </div>
             
            </div>
            :
            <div
              className={`flex overflow-x-scroll items-center ml-5 mt-5 max-sm:mt-10 gap-5 max-sm:gap-3 w-[50%] max-sm:w-[100%] max-sm:ms-4  "flex-row"
                  }`}
            >
                <div
                  className="lg:w-[60%] max-sm:w-[300px] max-sm:ml-1 flex-none bg-[#FFFEEF] lg:h-[160px] max-sm:h-[120px] rounded-xl shadow-md"
                >
                  <div className="ms-2 mt-1">
                    <p className="mt-5 text-[18px] max-sm:text-[13px]  ">
                    {ReactHtmlParser(`<span className="override-html">${truncateText(cityData?.tips, 50)}</span>`)}
                    </p>

                    {/* <p className="mt-8 max-sm:mt-4 text-center items-center text-[18px] max-sm:text-[10px]">
                      {language === "ar"
                        ? truncateText(tip.description, 22)
                        : truncateText(tip.description, 22)}
                    </p> */}
                  </div>
                </div>
             
            </div>
          )
          }




        </div>

        <div dir={language === "ar" ? "rtl" : "ltr"}>
          {/* {load === true ? ( */}
          <div className="lg:py-2 ">
            <div className={`flex ${language === "ar" ? "lg:mr-5" : "lg:ms-20 max-sm:ms-4"} mt-32 max-sm:mt-0`}>
              <div>
                <p className="text-[25px] max-sm:text-[16px] font-light">
                  {language === "ar" ? "استكشاف" : "Exploring"}
                </p>
                <p className="lg:text-[40px] max-sm:text-[20px] font-bold text-[#5B27D5] mt-1">
                  {language === "ar" ? `${cityData?.ar_name}'s جمال` : `${cityData?.name}'s Beauty`}
                </p>
              </div>
              <div className="lg:w-[60%] max-sm:w-[50%]" style={{ borderBottom: "1px solid black" }}></div>

              <div className={`max-sm:hidden ms-10 mt-5 ${language === "ar" ? "hidden" : ""}`}>
                <KeyboardArrowLeftIcon
                  className="rounded-full cursor-pointer"
                  style={{ border: "1px solid black", fontSize: "40px" }}
                />
                <KeyboardArrowRightIcon
                  className="rounded-full ms-4 cursor-pointer text-[#5B27D5]"
                  style={{ border: `1px solid `, fontSize: "40px" }}
                />
              </div>

            </div>

            <div
              className={`lg:w-[94%] max-sm:w-[100%] bg-gradient-to-b from-white via-[#f4f1cf] to-white flex ${language === "en" ? "lg:ms-20 max-sm:ms-5" : ""}  lg:gap-10 max-sm:-space-x-5 overflow-y-hidden overscroll-x-contain overflow-x-scroll mt-5`}
            >
              {poi?.length > 0 ? (
                poi.map((i) => {
                  // Calculate `city2` and `url2` inside the map
                  const city2 = i?.cityData?.name?.toLocaleLowerCase().replace(/\s+/g, '-');
                  const url = `${i.slug}-${i?.cityData?.name}-${i?.countryData?.name}-poi-${i._id}`;
                  const url2 = url.toLowerCase().replace(/\s+/g, '-');
                  const image = i.images[0]
                  const isArabic = language === "ar";
                  const navigateUrl = isArabic
                    ? `/ar/place/${city2}/${url2}`
                    : `/en/place/${city2}/${url2}`;
                  const title = isArabic ? i.ar_title : i.title;
                  const stateName = isArabic ? i?.stateData?.ar_name : i?.stateData?.name;
                  const description = isArabic
                    ? truncateText(i.ar_shortDescription, 130)
                    : truncateText(i.shortDescription, 130);

                  return (
                    <div
                      key={i._id}
                      className="lg:w-[404px]  max-sm:w-[90%] flex-none mb-5 cursor-pointer"
                      onClick={() => {
                        navigate(navigateUrl);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <div>
                        <img
                          loading="lazy"
                          src={image}
                          alt={`${title} image`}
                          className="lg:w-[404px] lg:h-[280px] max-sm:w-[90%] max-sm:h-[200px] rounded-md hover:shadow-xl"
                        />
                      </div>
                      <div className="py-4">
                        <p className="text-[20px] max-sm:text-[15px]">
                          <b className="text-[22px] max-sm:text-[20px]">{title}, </b>
                          {stateName}
                        </p>
                        <div
                          className="lg:mt-5 max-sm:w-[90%] max-sm:mt-3 text-[17px] max-sm:text-[10px] lg:leading-5 max-sm:leading-3"
                          dangerouslySetInnerHTML={{
                            __html: `<span class="override-html">${truncateText(description, 30)}</span>`,
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>
                  {language === "ar"
                    ? "لا توجد نقاط اهتمام متاحة."
                    : "No Points of Interest available."}
                </p>
              )}

            </div>
            <div className={`lg:mt-0 max-sm:-mt-10 mb-5 ${language === "ar" ? "lg:me-20 max-sm:me-5" : "lg:ms-20 max-sm:ms-5"}`}>
              {/* For English */}
              {language === "en" && (
                <>
                  <button
                    onClick={handelExploreMore}
                    className="max-sm:hidden border-2 border-black font-semibold rounded-full -mt-28 max-sm:text-[15px] max-sm:px-3 max-sm:p-0 px-7 py-1 hover:bg-[#5B27D5] hover:border-white hover:text-white"
                  >
                    Explore More <ChevronRightIcon />
                  </button>
                  <button
                    onClick={handelExploreMore}
                    className="lg:hidden border-2 border-black font-semibold rounded-full mt-3 max-sm:text-[14px] max-sm:px-3 max-sm:p-0 px-7 py-1 hover:bg-[#5B27D5] hover:border-white hover:text-white"
                  >
                    Explore More <ChevronRightIcon sx={{ fontSize: "16px" }} />
                  </button>
                </>
              )}

              {/* For Arabic */}
              {language === "ar" && (
                <div dir="rtl" className="flex justify-start items-center">
                  <button
                    onClick={handelExploreMore}
                    className="max-sm:hidden border-2 border-black font-semibold rounded-full -mt-7 me-16 max-sm:text-[15px] max-sm:px-3 max-sm:p-0 px-7 py-1 hover:bg-[#5B27D5] hover:border-white hover:text-white"
                  >
                    المزيد من الاستكشاف <ChevronRightIcon sx={{ transform: "rotate(180deg)" }} />
                  </button>
                  <button
                    onClick={handelExploreMore}
                    className="lg:hidden border-2 border-black font-semibold rounded-full mt-2 me-4 max-sm:text-[14px] max-sm:px-3 max-sm:p-0 px-7 py-1 hover:bg-[#5B27D5] hover:border-white hover:text-white"
                  >
                    المزيد من الاستكشاف <ChevronRightIcon sx={{ fontSize: "16px", transform: "rotate(180deg)" }} />
                  </button>
                </div>
              )}

            </div>
          </div>
          {/* //     ) : (
      //       <div className="flex  lg:ms-20 max-sm:ms-5 lg:gap-20 max-sm:gap-5  overscroll-x-conatin overflow-x-scroll mt-5">

      //         <CardList2 />
      //         <CardList2 />
      //         <CardList2 />
      //         <CardList2 />
      //         <CardList2 />

      //       </div>
      //  )}  */}
        </div>

        <div>
          <Category page={category} language={language} />


        </div>

      </div>
      <AllCuisines language={language} />
      <Footer language={language} />
    </>
  );
}

export default CityView;
