import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import BlogDropdown from "../../Components/Blogs";
import { Link } from "react-router-dom";

function AllBlogs({ language }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [blogs, setBlogs] = useState([]);
    const totalPages = 11;
    const siteURL = "balwadaanil0783.wordpress.com"; // Your WordPress site

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await fetch(`https://public-api.wordpress.com/rest/v1.1/sites/${siteURL}/posts/`);
                const data = await response.json();
                console.log("Fetched Blogs:", data);
                if (data.posts) {
                    setBlogs(data.posts);
                }
            } catch (error) {
                console.error("Error fetching blogs:", error);
            }
        };

        fetchBlogs();
    }, []);

    const extractFirstImage = (content) => {
        const match = content.match(/<img[^>]+src="([^">]+)"/);
        return match ? match[1] : null;
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className="px-2">
            <div className="lg:ml-40 mt-8">Home {">"} Blogs</div>
            <div className="lg:flex lg:ml-40 lg:mt-20 max-sm:mt-5">
                <div className="lg:w-[25%] max-sm:[100%]">
                    <h2 className="font-bold text-[20px] mt-2">Blogs</h2>
                    <BlogDropdown />
                </div>

                <div className="lg:w-[65%] max-sm:w-[100%]">
                    <div className="flex justify-between">
                        <div className="font-semibold text-[18px] mt-1 text-gray-400">
                            {blogs.length} results
                        </div>
                        <div className="relative">
                            <SearchIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                            <input
                                type="text"
                                className="outline-none rounded-lg py-1 pl-3 lg:pr-10 border border-black"
                                placeholder="Search blog..."
                            />
                        </div>
                    </div>

                    {blogs.map((blog) => {
                        const firstImage = extractFirstImage(blog.content);
                        return (
                            <div key={blog.ID} className="mt-7 w-full rounded-xl shadow-lg py-2">
                                {firstImage && (
                                    <img
                                        src={firstImage}
                                        style={{ width: "100%", height: "300px", borderRadius: "10px" }}
                                        alt="Blog Cover"
                                    />
                                )}
                                <div className="lg:flex">
                                    <div className="mt-3 ml-3 w-[70%]">
                                        <p>
                                            <LocalOfferOutlinedIcon
                                                style={{ fontSize: "15px", color: "gray" }}
                                            />{" "}
                                            {blog.categories ? Object.values(blog.categories).join(", ") : "General"}
                                        </p>
                                        <p className="text-[17px] font-semibold mt-5">{blog.title}</p>
                                        <p
                                            className="mt-3 text-gray-400 leading-4 pb-4"
                                            dangerouslySetInnerHTML={{ __html: blog.excerpt }}
                                        />
                                    </div>
                                    <button className="lg:ml-36 max-sm:ml-5 text-gray-500 lg:mt-16 text-[15px]">
                                        <Link to={`/blog/${blog.ID}`} rel="noopener noreferrer">
                                            Read More <ArrowForwardOutlinedIcon />
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* <div className="flex items-center justify-center mt-20 space-x-3">
                <button onClick={handlePrevPage} className="px-2 py-1 rounded hover:bg-gray-200" disabled={currentPage === 1}>
                    &lt;
                </button>
                <span>
                    <span className="px-2 py-1 border border-gray-300 rounded">{currentPage}</span> of {totalPages}
                </span>
                <button onClick={handleNextPage} className="px-2 py-1 rounded hover:bg-gray-200" disabled={currentPage === totalPages}>
                    &gt;
                </button>
            </div> */}
        </div>
    );
}

export default AllBlogs;
