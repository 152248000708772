import { useEffect, useState } from "react";
import React from "react";
import AccordionUsage from "../../Components/Poi/Accordin";
import Slider from "../../Components/Poi/Slider";
import Description from "../../Components/Poi/Description";
import LanguageIcon from "@mui/icons-material/Language";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { Link, useLocation, useParams } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import PopularDestination from "../../Components/PopularDestination";
import PopularDestinationCard from "../../Components/PopularDestination/PopularDestinationCard";
import { primary } from "../../AppConfig";
import { url } from "../../urlConfig";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Lines from "../../Components/Shimmer/Lines";
import Boxes from "../../Components/Shimmer/Boxes";
import Cookies from "js-cookie";
import axios from "axios";
function Poi({ initialData = {}, language,currency }) {
  //   const [qa, setQa] = useState([]);
  const [ar_shortDescription, Setar_shortDescription] = useState("")
  const [shortDescription, setShortDescription] = useState("");
  const [fontSize, setFontSize] = useState("20px");
  const [lineHeight, setLineHeight] = useState("10");
  const [lang, setLang] = useState(language);
  const { url } = useParams();
  const poiId = url.split("-").pop();
  const location = useLocation();
  const [poi, setPoi] = useState(initialData.poi?.data || {});
  const [images, setImages] = useState(initialData.poi?.data?.images || []);
  const [load, setLoad] = useState(!!initialData.poi);
  const [category, setCategory] = useState(initialData.category?.data || []);

  useEffect(() => {
    if (initialData.poi === undefined || initialData.category === undefined) {
      const fetchPoiData = async () => {
        try {
          const poiResponse = await axios.get(`https://admin.hopdays.com/api/single-poi/${poiId}`);
          setPoi(poiResponse.data.data);
          setImages(poiResponse.data.data.images);
          setLoad(true);
        } catch (error) {
          console.error("Error fetching data on client-side navigation:", error);
          setLoad(false);
        }
      };
      fetchPoiData();
    } else {
      setLoad(true);
    }
  }, [poiId, initialData]);

  if (!load) {
    return <Lines width={"50%"} />;
  }

  if ((lang === "en" && !poi.isForEnglish) || (lang === "ar" && !poi.isForArabic)) {
    return (
      <div className="text-center py-20 text-xl font-medium">
        Content not available in the selected language
      </div>
    );
  }

  return (
    <div className="px-5 lg:px-20 lg:mt-10 max-sm:mt-5">
      <div className={`${lang === "ar" ? "text-right" : "text-left"}`}>
        <h1 className="lg:text-[70px] max-sm:text-[25px] font-medium" style={{ fontFamily: "inter" }}>
          {lang === "ar" ? poi.ar_title : poi.title}
        </h1>
        <p className="mt-4 max-sm:mt-2 text-[20px] max-sm:text-[16px] font-light">
          {lang === "ar" ? (
            <>
              <Link
                to={`/ar/trip/${poi?.cityData?.slug.toLocaleLowerCase().replace(/\s+/g, "-")}-tourism-${poi.countryData?.name.toLocaleLowerCase().replace(/\s+/g, "-")}-${poi.cityData?._id}-ctytsmid`}
                className="text-blue-500 hover:underline"
              >
                {poi.cityData?.ar_name}
              </Link>
              ,{" "}
              <Link
                to={`/ar/travel/${poi?.stateData?.slug.toLocaleLowerCase().replace(/\s+/g, "-")}-tourism-${poi.countryData?.name.toLocaleLowerCase().replace(/\s+/g, "-")}-${poi.stateData?._id}-sttsmid`}
                className="text-blue-500 hover:underline"
              >
                {poi.stateData?.ar_name}
              </Link>
              , {poi.countryData?.ar_name}
            </>
          ) : (
            <>
              <Link
                to={`/en/trip/${poi?.cityData?.slug.toLocaleLowerCase().replace(/\s+/g, "-")}-tourism-${poi.countryData?.name.toLocaleLowerCase().replace(/\s+/g, "-")}-${poi.cityData?._id}-ctytsmid`}
                className="text-blue-500 hover:underline"
              >
                {poi.cityData?.name}
              </Link>
              ,{" "}
              <Link
                to={`/en/travel/${poi?.stateData?.slug.toLocaleLowerCase().replace(/\s+/g, "-")}-tourism-${poi.countryData?.name.toLocaleLowerCase().replace(/\s+/g, "-")}-${poi.stateData?._id}-sttsmid`}
                className="text-blue-500 hover:underline"
              >
                {poi.stateData?.name}
              </Link>
              , {poi.countryData?.name}
            </>
          )}
        </p>
      </div>

      <div className={`flex flex-wrap gap-3 mt-3 max-sm:hidden ${lang === "ar" ? "flex-row-reverse" : ""}`}>
        {poi?.tags?.map((tag, index) => (
          <div className="border text-[15px] border-[#A5A5A5] px-4 rounded-md" key={index}>
            {tag}
          </div>
        ))}
      </div>

      {load ? (
        lang === "ar" ? (
          <div className="flex justify-between flex-col lg:flex-row lg:gap-10 w-full mt-5 max-sm:mt-10 items-center">
            <div className="mt-10 lg:mt-0 lg:w-2/5 w-full">
              <p
                className="lg:text-4xl text-[36px] italic"
                dangerouslySetInnerHTML={{ __html: poi.ar_blurb }}
              />
              <div
                className="mt-3 text-[20px] max-sm:text-[13px] leading-6 max-sm:leading-5"
                dangerouslySetInnerHTML={{ __html: `<span class="override-html">${poi.ar_shortDescription}</span>` }}
              />
            </div>
            <div className="lg:w-[793px] max-sm:w-[370px]">
              <Slider images={images} />
            </div>
          </div>
        ) : (
          <div className="flex justify-between flex-col lg:flex-row lg:gap-10 w-full mt-5 max-sm:mt-10 items-center">
            <div className="lg:w-[793px] max-sm:w-[370px]">
              <Slider images={images} />
            </div>
            <div className="mt-10 max-sm:hidden lg:mt-0 max-sm:text-center lg:w-1/2 w-full">
              <p
                className="lg:text-4xl text-xl italic"
                dangerouslySetInnerHTML={{ __html: `<span class="override-htmlb">${poi.blurb}</span>` }}
              />
              <div
                className="mt-3 text-[20px] max-sm:text-[13px] leading-6 max-sm:leading-5"
                dangerouslySetInnerHTML={{ __html: `<span class="override-html">${poi.shortDescription}</span>` }}
              />
            </div>
          </div>
        )
      ) : (
        <div className="flex justify-between flex-col lg:flex-row lg:gap-10 w-full mt-5 items-center">
          <div className="mt-10 lg:mt-0 lg:w-full w-full">
            <Boxes />
          </div>

          :
          <div className="">
            <div className=" font-semibold text-[25px] lg:mt-[70px] max-sm:mt-[30px] max-sm:text-[16px]">
              {lang === "ar" ? "أشياء مهمة يجب معرفتها:" : "Important things to know :"}
            </div>
            {lang === "ar" ?
              <div className="" >
                <AccordionUsage qa={poi?.ar_thingsToKnow} language={language} currency={currency}/>
              </div>
              :
              <div className="" >
                <AccordionUsage qa={poi?.thingsToKnow} language={language} currency={currency}/>
              </div>
            }

          </div>
        </div>
      )}

      <div className="mt-10">
        <Description key={poi?._id} description={poi?.description} ar_description={poi?.ar_description} language={language} />
      </div>

        {((lang === "ar" && poi?.ar_peopleSays?.length > 0) ||
          (lang !== "ar" && poi?.peopleSays?.length > 0)) && (
            <div className="mt-28 max-sm:mt-8">
              <div className="font-semibold text-[25px] max-sm:text-[16px]">
                {lang === "ar" ? "الأسئلة التي قد تهمك" : "People also ask:"}
              </div>
              <div>
                <AccordionUsage
                  qa={lang === "ar" ? poi?.ar_peopleSays : poi?.peopleSays}
                  language={language}
                  currency={currency}
                />
              </div>
            </div>
          )}

      <div className="lg:hidden max-sm:w-full mt-10" style={{ borderBottom: "2px solid gray" }} />

      {((lang === "ar" && poi?.ar_tips?.length > 0) || (lang !== "ar" && poi?.tips?.length > 0)) && (
        <div className="lg:mt-20 max-sm:mt-[30px] font-semibold text-[25px] max-sm:text-[16px]" dir={lang === "ar" ? "rtl" : "ltr"}>
          {lang === "ar" ? "نصائح هامة" : "Important Tips"}
          <div className="gap-5 lg:mt-5 max-sm:mt-2 max-sm:gap-2">
            {(lang === "ar" ? poi?.ar_tips : poi?.tips)?.map((tip, key) => (
              <div key={key} className={`flex flex-col gap-2 ${lang === "ar" ? "text-right" : "text-left"}`} dir={lang === "ar" ? "rtl" : "ltr"}>
                <div className="flex items-start gap-2">
                  <span className="text-lg mb-2">•</span>
                  <p className="text-[18px] mt-1 max-sm:mt-2 max-sm:text-[14px] font-medium">{tip}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="lg:flex lg:justify-between w-full mt-10 max-sm:mt-10">
        <div className="lg:w-[70%] max-sm:w-[60%] max-sm:hidden">
          <p className="font-semibold text-[25px] max-sm:text-[16px]">{lang === "ar" ? "عنوان" : "Address"}</p>
          <p className="lg:text-[20px] max-sm:text-[16px] mt-3 max-sm:mt-3 text-[#454545]">
            {lang === "ar" ? poi.ar_address : poi.address}
            {poi.latitude && poi.longitude && (
              <a
                href={`https://www.google.com/maps?q=${poi.latitude},${poi.longitude}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline ml-5"
              >
                Visit
              </a>
            )}
          </p>
        </div>
        <div className="lg:hidden mt-10 max-sm:w-[100%]">
          <p className="font-semibold text-[25px] max-sm:text-[16px]">{lang === "ar" ? "عنوان" : "Address"}</p>
          <p className="lg:text-[18px] max-sm:text-[16px] mt-[30px] max-sm:mt-3 text-[#454545] flex items-center gap-2">
            {lang === "ar" ? poi?.ar_address : poi?.address}
            {poi.latitude && poi.longitude && (
              <a
                href={`https://www.google.com/maps?q=${poi.latitude},${poi.longitude}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline"
              >
                Visit
              </a>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Poi;