import PlaceIcon from '@mui/icons-material/Place';
import React from "react";
import Cookies from "js-cookie"
import { useNavigate } from "react-router-dom";


function PopularDestinationCard({key,state,language}) {
    const navigate = useNavigate();
    const handelView = () => {
        navigate(`/${language}/travel/${state?.slug.toLocaleLowerCase().replace(/\s+/g, '-')}-tourism-${state?.Country?.name.toLocaleLowerCase().replace(/\s+/g, '-')}-${state._id}-sttsmid`);
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This adds a smooth scroll effect
        });
    };

    const lang = language || "";

    // Destructure props with fallback
    // const { state = {} } = props;
    const { Image, name, ar_name,_id } = state;

    const countryName = state.Country ? state.Country.name : 'Country';
    const ar_countryName = state.Country ? state.Country.ar_name : 'Country';

    return (
        <div
        onClick={handelView}
        key={key}
        className="lg:w-[20%] lg:h-[350px] max-sm:h-[260px] rounded-md text-center cursor-pointer flex-none"
        id="popular-destination-card"
    >
        <div className="" id="popular-destination-card-image">
            <img
                src={Image || 'fallback-image-url.jpg'}
                alt={name || 'Default Name'}
                className="rounded-xl lg:w-[276px] lg:h-[350px] max-sm:w-[200px] max-sm:h-[260px]"
            />
        </div>
        {lang === "ar" ? (
            <div
                className="relative text-center bottom-[60px] max-sm:bottom-[52px] flex flex-col justify-center items-center"
                id="popular-destination-card-text"
            >
                <p className="text-white font-semibold max-sm:text-[20px] lg:text-[30px]">
                    {ar_name || 'Default Arabic Name'}
                </p>
                <p className="text-white max-sm:text-[14px] lg:mt-1 flex items-center">
                    <PlaceIcon sx={{ fontSize: "14px" }} /> {ar_countryName || 'Country'}
                </p>
            </div>
        ) : (
            <div
                className="relative text-center lg:bottom-[60px]  max-sm:bottom-[52px] flex flex-col justify-center items-center"
                id="popular-destination-card-text"
            >
                <p className="text-white font-semibold max-sm:text-[20px] lg:text-[30px]">
                    {name || 'Default Name'}
                </p>
                <p className="text-white lg:mt-1 max-sm:mt-1  max-sm:text-[14px] flex items-center">
                    <PlaceIcon sx={{ fontSize: "14px" }} /> {countryName || 'Country'}
                </p>
            </div>
        )}
    </div>
    
    );
}

export default PopularDestinationCard;
