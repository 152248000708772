import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function AccordionUsage({ qa,language,currency }) {
  const [fontSize, setFontSize] = React.useState("20px");
  const [lineHeight, setLineHeight] = React.useState("10");
 
  // const currency =localStorage.getItem("currency")
  const currency2 = currency?.find((i)=>i.name==language)
  console.log("Currency",currency2)
  React.useEffect(() => {
    // Handle responsive font size
    const handleResize = () => {
      if (window.matchMedia("(max-width: 640px)").matches) {
        setFontSize("10px");
        setLineHeight("2");
      } else {
        setFontSize("18px");
        setLineHeight("20");
      }
    };

   
    
    // Initial check and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const sanitizeAnswer = (answer, language) => {
    return answer.replace(/\{(\d+)\}/g, (match, number) => {
      return language === "ar" ? `${Number(number) * currency2?.value}` : number;
    });
  };
  return (
    <div>
      {qa?.map((i, key) => {
        // Style the answer dynamically
        const styledAnswer = i.answer?.replace(
          /<span[^>]*>/g,
          `<span style="font-size: ${fontSize}; line-height: ${lineHeight};">`
        );

        const sanitizedAnswer = typeof styledAnswer === "string" ? styledAnswer : "";

        return (
          <Accordion key={key} sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${key}-content`}
              id={`panel${key}-header`}
              className="font-regular text-[#454545] -ml-3  max-sm:text-[16px]"
              sx={{ boxShadow: "none", marginLeft: -2 }}
            >
              {i.question}
            </AccordionSummary>
            <AccordionDetails
  className="font-regular text-[#454545] -ml-3 text-[20px] max-sm:text-[16px] leading-6 max-sm:leading-5"
  dangerouslySetInnerHTML={{
    __html: `<span class="override-htmlq">${sanitizeAnswer(sanitizedAnswer, language)}</span>`,
  }}
></AccordionDetails>

          </Accordion>
        );
      })}
    </div>
  );
}

export default AccordionUsage;
