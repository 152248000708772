import { useEffect, useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Cookies from 'js-cookie';
import {
  Dialog,
  DialogPanel,
  Popover,
  PopoverPanel,
  PopoverGroup,
} from '@headlessui/react';
import {
  Bars3Icon,
  PhoneIcon,
  PlayCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ArrowPathIcon, CursorArrowRaysIcon, FingerPrintIcon, SquaresPlusIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { primary } from '../AppConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from "../public/images/hopdayslogo.png"
const products = [
  { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: ArrowPathIcon },
  { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
  { name: 'Security', description: 'Your customers’ data will be safe and secure', href: '#', icon: FingerPrintIcon },
  { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: SquaresPlusIcon },
  { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: ArrowPathIcon },
];

const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function NavBar({ language }) {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation()
  const [lang, setLang] = useState(language)
  // const lang = language || '';
  // const currentUrl = window.location.href;
  let navigate = useNavigate()
  //  useEffect(() => {
  //      if (location.pathname.includes("en") && Cookies.get('lang') === "ar") {
  //        Cookies.set('lang', "en", { expires: 7 });
  //        setLang("en");
  //        window.location.reload()
  //      }
  //      if (location.pathname.includes("ar") && Cookies.get('lang') === "en") {
  //        Cookies.set('lang', "ar", { expires: 7 });
  //        setLang("ar");
  //        window.location.reload()
  //      }
  //    }, [location]);

  return (
    <header className="bg-white shadow-lg z-10 py-2 max-sm:py-0 sticky top-0 w-full">
      {lang === 'ar' ? (
        <nav className="mx-auto gap-40 flex flex-row-reverse items-center justify-between p-1 " aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="w-[150px]" src={logo} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <PopoverGroup className="hidden lg:flex lg:gap-x-12">
            <Popover className="relative">
              <PopoverPanel
                transition
                className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </PopoverPanel>
            </Popover>
            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
              يستكشف
            </a>
            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
              معلومات عنا
            </a>
          </PopoverGroup>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="#" className="text-sm font-semibold leading-6 text-gray-900 px-3">
              <input
                type="text"
                className="outline-none rounded-full py-1 ms-5"
                placeholder="وجهة البحث... "
                style={{ border: `1px solid ${primary}` }}
              />
            </a>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">

            <a
              href="#"
              className="text-[18px] font-medium   px-5 py-1 rounded-full text-white"
              style={{ background: `${primary}` }}
            >
              اشتراك

            </a>
            <a href="#" className="text-[18px]  font-regular leading-6 text-gray-900 px-3 py-1">
              <LanguageIcon /> |
              <select
                className="border-none text-md mt-0"
                onChange={(e) => {
                  Cookies.set('lang', e.target.value, { expires: 7 });
                  setLang(e.target.value);
                  if (Cookies.get('lang') === "ar") {
                    const newUrl = location.pathname.replace("/en/", "/ar/");
                    navigate(newUrl);
                    window.location.reload()
                  }
                  if (Cookies.get('lang') === "en") {
                    const newUrl = location.pathname.replace("/ar/", "/en/");
                    navigate(newUrl);
                    window.location.reload()
                  }
                }}
                value={lang}
              >
                <option value="en">English</option>
                <option value="ar">عربي</option>
              </select>
            </a>
          </div>
        </nav>
      ) : (
        <nav className="mx-auto bg-white flex items-center justify-between p-1 lg:px-8" aria-label="Global">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="w-[150px]" src={logo} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <PopoverGroup className="hidden lg:flex lg:gap-x-12">
            <Popover className="relative">
              <PopoverPanel
                transition
                className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    >
                      <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </PopoverPanel>
            </Popover>
            {/* <a href="#" className="text-[18px] font-medium leading-6 text-gray-900">
              Explore
            </a>
            <a href="#" className="text-[18px] font-medium leading-6 text-gray-900">
              About Us
            </a> */}
          </PopoverGroup>
          {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="#" className="text-sm font-medium leading-6 text-gray-900 px-3">
              <div className="relative flex items-center">
                <SearchIcon className="absolute left-8 " />
                <input
                  type="text"
                  className="outline-none rounded-full py-1 pl-10 pr-3 ms-5"
                  placeholder="search destination..."
                  style={{ border: `1px solid ${primary}` }}
                />
              </div>
            </a>
          </div> */}
          <div className="hidden lg:flex lg:flex-1 ml-10">
            <a href="#" className="text-[18px]  font-regular leading-6 text-gray-900 px-3 py-1">
              <LanguageIcon /> |
              <select
                className="border-none text-md mt-0"
                onChange={(e) => {
                  Cookies.set('lang', e.target.value, { expires: 7 });
                  setLang(e.target.value);
                  if (Cookies.get('lang') === "ar") {
                    const newUrl = location.pathname.replace("/en/", "/ar/");
                    navigate(newUrl);
                    window.location.reload()
                  }
                  if (Cookies.get('lang') === "en") {
                    const newUrl = location.pathname.replace("/ar/", "/en/");
                    navigate(newUrl);
                    window.location.reload()
                  }
                }}
                value={lang}
              >
                <option value="en">English</option>
                <option value="ar">عربي</option>
              </select>
            </a>
            {/* <a
              href="#"
              className="text-[18px] font-medium ml-2  px-5 py-1 rounded-full text-white"
              style={{ background: `${primary}` }}
            >
              Sign Up
            </a> */}
          </div>
        </nav>
      )}
      <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
        <div className="fixed inset-0 z-10 bg-black/50" />
        <DialogPanel className="fixed inset-0 z-20 w-full bg-white px-6 py-6">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-8 w-auto" src={logo} alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Explore
                </a>
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  About Us
                </a>
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Company
                </a>
              </div>
              <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-medium leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
