import React from "react";
import { useEffect, useState } from "react";
import { primary } from "../../AppConfig";
import { url } from "../../urlConfig";
import AttractionCard from "./AttractionCard";
import CardList from "../Shimmer/Cards";
import Cookies from "js-cookie"
import axios from "axios";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from "react-router-dom";


function Attractions({ poiData,language }) {
  const navigate = useNavigate();
  // const cards=["1","2","3","4","5","6",]
  const [poi, setPoi] = useState(poiData)
  const [load, setLoad] = useState(!!poiData);
  useEffect(() => {
    // console.log("Params:", poiId); // Log params
    // console.log("Initial Data:", initialData); // Log initial data
    // console.log(poiData)
    if (poiData === undefined) {
      const fetchPoiData = async () => {
        try {
          const poiResponse = await axios.get(`${url}/pois_of_popular_city/${language}`);
          setPoi(poiResponse.data.data);
          console.log(poiResponse.data)


          setLoad(true);
        } catch (error) {
          console.error("Error fetching data on client-side navigation:", error);
          setLoad(false);
        }
      };

      fetchPoiData();
    }
  }, [poi]);
const categoryId="all"
  const handelExploreMore = () => {
    navigate(`/${language}/all-poi/${categoryId}`); window.scrollTo({
      top: 0,
      behavior: 'smooth', // This adds a smooth scroll effect
    })
  };


  return (
    <>

      {load === true ?

        <div className="bg-gradient-to-b from-white via-[#f4f1cf] to-white py-2">


          <div>
            {language === "ar" ?
              <div className="flex justify-end me-4 max-sm:ms-5 lg:mt-10">

                <div className="lg:w-[60%] max-sm:w-[30%]" style={{ borderBottom: "1px solid black" }}>

                </div>
                <div>
                  <p className="text-xl flex justify-end  max-sm:text-[16px]">الاستكشاف</p>
                  <p className="lg:text-4xl max-sm:text-[20px] font-bold  lg:mt-2 max-sm:mt-1" style={{ color: `${primary}` }}>مناطق الجذب التي يجب مشاهدتها
                  </p>
                </div>
              </div>
              :
              <div className="flex lg:ms-20 max-sm:ms-5 lg:mt-10 max-sm:mt-5">
                <div>
                  <p className="text-[25px] font-light max-sm:text-[16px]">Exploring</p>
                  <p className="lg:text-[40px] max-sm:text-[20px] font-bold lg:mt-2 max-sm:mt-1" style={{ color: `${primary}` }}>Must-see Attractions</p>
                </div>
                <div className="lg:w-[55%] max-sm:w-[35%]" style={{ borderBottom: "1px solid black" }}>

                </div>
                <div className="max-sm:hidden ms-10 mt-6">
              <KeyboardArrowLeftIcon className="rounded-full cursor-pointer" style={{border:"1px solid black",fontSize:"40px"}}/>
              <KeyboardArrowRightIcon className="rounded-full ms-4 cursor-pointer" style={{border:`1px solid ${primary}`,fontSize:"40px"}}/>
              </div>
              </div>
            }


          </div>
          {language === "ar" ?
            <div className=" lg:w-[95%]  max-sm:w-[100%] flex flex-row-reverse max-sm:me-5  lg:gap-5 max-sm:gap-5 overflow-y-hidden  overscroll-x-conatin overflow-x-scroll lg:mt-8 max-sm:mt-5 ">
              {poi.map((i,key) => (
                <AttractionCard key={key} poi={i} language={language} />
              ))}

            </div>
            :
            <div className="flex lg:w-[90%] max-sm:w-[100%] lg:ms-20 max-sm:ms-5 lg:gap-5 max-sm:gap-0 overflow-y-hidden overscroll-x-conatin overflow-x-scroll lg:mt-8 max-sm:mt-5">
              {poi.map((i,key) => (
                <AttractionCard key={key} poi={i} language={language} />
              ))}

            </div>
          }
          {language === "ar" ?
            <div className=" flex flex-row-reverse mb-5 lg:-mt-8 max-sm:-mt-3 lg:mr-16 max-sm:mr-5">
              <button onClick={handelExploreMore} className="border-2 border-black rounded-full max-sm:text-[14px] mt-3 lg:px-8 max-sm:px-2 lg:py-1 font-semibold hover:bg-[#9747FF] hover:border-white hover:text-white ">اكتشف المزيد<ChevronRightIcon sx={{fontSize:"18px"}}/></button>
            </div>
            :
            <div className=" lg:ms-20 max-sm:ms-5 max-sm:-mt-3">
              <button onClick={handelExploreMore} className="border-2 border-black rounded-full font-semibold -mt-8  px-7 py-1 max-sm:text-[14px] max-sm:px-3 max-sm:py-0 hover:bg-[#9747FF] hover:border-white hover:text-white ">Explore More<ChevronRightIcon sx={{fontSize:"18px"}}/></button>
            </div>
          }
        </div>
        :
        <div className="flex px-20">
          <CardList />
        </div>
      }



    </>
  );
}

export default Attractions;