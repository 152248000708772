import React from "react";
import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import ReactHtmlParser from "html-react-parser";


function AttractionCard({poi,key,language}) {
  // const [poi, setPois] = useState({})
  const [image, setImage] = useState("")
  const [ar_shortDescription, Setar_shortDescription] = useState("")
  const [shortDescription, setShortDescription] = useState("");
  const [fontSize, setFontSize] = useState("17px");
  const [lineHeight, setLineHeight] = useState("2");
  const lang = language || "en"


  useEffect(() => {
    // Set description props
    setShortDescription(poi.shortDescription);
    Setar_shortDescription(poi.ar_shortDescription);

    // Handle responsive font size
    const handleResize = () => {
      if (window.matchMedia("(max-width: 640px)").matches) {
        setFontSize("10px");
        setLineHeight("2");
      } else {
        setFontSize("18px");
        setLineHeight("2");
      }
    };

    // Initial check and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [poi]);

  const currentDescription = lang === 'ar' ? ar_shortDescription : shortDescription;

  // Apply styles to both descriptions dynamically
  const styledDescription = currentDescription?.replace(
    /<span[^>]*>/g,
    `<span style="font-size: ${fontSize}; line-height: ${lineHeight};">`
  ).replace(
    /<p[^>]*>/g,
    `<p style="font-size: ${fontSize}; line-height: ${lineHeight};">`
  );

  const sanitizedDescription =
    typeof styledDescription === "string" ? styledDescription : "";




  useEffect(() => {
    
    setImage(poi?.images[0])
    setShortDescription(poi?.shortDescription)
    Setar_shortDescription(poi.ar_shortDescription)
    console.log(poi)
  }, [poi])
  let url = `${poi.slug}-${poi?.cityData?.name}-${poi?.countryData?.name}-poi-${poi._id}`
  let url2 = url.toLowerCase().replace(/\s+/g, '-');
  let city = `${poi?.cityData?.name}`
  let city2 = city.toLocaleLowerCase().replace(/\s+/g, '-')
  let navigate = useNavigate()
  function truncateText(text, maxLength) {
    if (text?.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  }
  // const truncatedDescription = truncateText(poi?.shortDescription, 100);
  return (
    <>
      {/* <NavLink to={`/${lang}/${poi.city}/${poi.slug}/${poi.city}/${poi.country}/poi/${poi._id}`}> */}
      {language == "ar" ?
        <div key={key} dir="rtl" className="lg:w-[404px]  max-sm:w-[85%] flex-none   mb-5 cursor-pointer" onClick={() => {
          navigate(`/ar/place/${city2}/${url2}`); window.scrollTo({
            top: 0,
            behavior: 'smooth', // This adds a smooth scroll effect
          })
        }}>
          <div>
            <img loading="lazy" src={image} className="lg:w-[404px] max-sm:w-[96%] lg:h-[280px] max-sm:h-[200px] rounded-md hover:shadow-xl" />
          </div>
          <div className="py-4">
            <p className="text-[20px] max-sm:text-[10px]"><b className=" text-[22px] max-sm:text-[13px]">{poi.ar_title}, </b>{poi?.stateData?.ar_name}</p>
            <div
              className="lg:mt-5 max-sm:mt-3 text-[17px] max-sm:text-[10px] lg:leading-5 max-sm:leading-3"
              dangerouslySetInnerHTML={{
                __html: `<span class="override-html">${truncateText(ar_shortDescription, 130)}</span>`,
              }}
            ></div>

          </div>
        </div>
        :
        <div key={key} className="lg:w-[404px]   max-sm:w-[85%] flex-none mb-5 cursor-pointer " onClick={() => {
          navigate(`/en/place/${city2}/${url2}`); window.scrollTo({
            top: 0,
            behavior: 'smooth', // This adds a smooth scroll effect
          })
        }}>
          <div>
            <img loading="lazy" src={image} className="lg:w-[404px] max-sm:w-[96%] lg:h-[280px] max-sm:h-[200px] rounded-md hover:shadow-xl" />
          </div>
          <div className="py-4">
            <p className="text-[20px] max-sm:text-[15px]"><b className=" text-[25px] font-semibold max-sm:text-[20px]">{poi.title}, </b>{poi?.stateData?.name}</p>
            <div
              className="lg:mt-5 max-sm:mt-3 max-sm:w-[95%] text-[17px] max-sm:text-[10px] lg:leading-5 max-sm:leading-3"
              dangerouslySetInnerHTML={{
                __html: `<span class="override-html">${truncateText(sanitizedDescription, 300)}</span>`,
              }}
            ></div>


          </div>
        </div>
      }
      {/* </NavLink> */}
    </>
  );
}

export default AttractionCard;