import PlaceIcon from '@mui/icons-material/Place';
import React from "react";
import Cookies from "js-cookie"
import { useNavigate } from "react-router-dom";


function PopularCityCard({city,stateId,state2,key,language}) {
    const lang = language || "";
    const navigate = useNavigate();
    const handelView = () => {
        navigate(`/${lang}/trip/${city?.slug.toLocaleLowerCase().replace(/\s+/g, '-')}-tourism-${state2?.country?.name.toLocaleLowerCase().replace(/\s+/g, '-')}-${city._id}-ctytsmid`);
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This adds a smooth scroll effect
        });
    };



    // Destructure props with fallback
    // const { city = {} } = props;
    const { image, name, ar_name, state, _id } = city;

    return (
        <div
            onClick={handelView}
            key={key}
            className=" lg:w-[20%] rounded-md text-center cursor-pointer flex-none"
            id="popular-destination-card"
        >
            <div className="" id="popular-destination-card-image">
                <img
                    src={image || 'fallback-image-url.jpg'}
                    alt={name || 'Default Name'}
                    className="rounded-xl lg:w-[276px] lg:h-[350px] max-sm:w-[200px] max-sm:h-[260px]"
                />
            </div>
            {lang === "ar" ? (
                <div className="relative text-center bottom-[60px] max-sm:bottom-[45px]" id="popular-destination-card-text">
                    <p className="text-white font-bold max-sm:text-[20px] lg:text-[30px]">
                        {ar_name || 'Default Arabic Name'}
                    </p>
                    <p className="text-white max-sm:mt-1 max-sm:text-[14px]">
                        <PlaceIcon sx={{fontSize:"14px"}}/> {state || ' state'}
                    </p>
                </div>
            ) : (
                <div className="relative text-center bottom-[60px] max-sm:bottom-[45px]" id="popular-destination-card-text">
                    <p className="text-white font-bold  max-sm:text-[20px] lg:text-[30px]">
                        {name || 'Default Name'}
                    </p>
                    <p className="text-white lg:mt-1 max-sm:mt-1  max-sm:text-[14px]  items-center">
                        <PlaceIcon sx={{fontSize:"14px"}}/> {state || ' state'}
                    </p>
                </div>
            )}
        </div>
    );
}

export default PopularCityCard;
